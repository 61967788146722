import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import "../components/layout.css";
import Share from "../components/Share";
import I18nLink from "../i18n/Link";
import PageWrapper from "./PageWrapper";

interface ScopeProps {
    lang: string;
    href: string;
}

export default function Scope({ lang, href }: ScopeProps) {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: "tssmtitle" });
    return (
        <>
            <PageWrapper
                title={title}
                keywords={["scope", "methodology", "taking stock"]}
                lang={lang}
                originalPath="scope"
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                        style={{
                            maxWidth: 900,
                            margin: 50,
                            paddingTop: 50,
                            paddingBottom: 50
                        }}
                    >
                        <h1 style={{ fontSize: 36 }}>
                            <FormattedMessage id="tssmtitle" />
                        </h1>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage id="tssmp1" />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="tssmp2"
                                values={{
                                    style: (
                                        <strong>
                                            <FormattedMessage id="stylep2" />
                                        </strong>
                                    )
                                }}
                            />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="tssmp3"
                                values={{
                                    link1: (
                                        <I18nLink to="/prtr">
                                            <FormattedMessage id="tssmp3link" />
                                        </I18nLink>
                                    )
                                }}
                            />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="tssmlist1"
                                values={{
                                    style: (
                                        <em>
                                            <FormattedMessage id="liststyle1" />
                                        </em>
                                    )
                                }}
                            />
                        </p>
                        <ul style={{ fontSize: 18, lineHeight: 1.5 }}>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet1"
                                    values={{
                                        link1: (
                                            <I18nLink to="/terminology">
                                                <FormattedMessage id="tssmbullet1link" />
                                            </I18nLink>
                                        ),
                                        style: (
                                            <em>
                                                <FormattedMessage id="liststyle1" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage id="tssmbullet2" />
                            </li>
                            <li>
                                <FormattedMessage id="tssmbullet3" />
                            </li>
                            <li>
                                <FormattedMessage id="tssmbullet4" />
                            </li>
                        </ul>
                        <h2 style={{ fontSize: 22 }}>
                            <FormattedMessage id="tssmheader1" />
                        </h2>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage id="tssmp4" />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage id="tssmlist2" />
                        </p>
                        <ul style={{ fontSize: 18, lineHeight: 1.5 }}>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet5"
                                    values={{
                                        style: (
                                            <em>
                                                <FormattedMessage id="stylebullet5" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet6"
                                    values={{
                                        style: (
                                            <em>
                                                <FormattedMessage id="stylebullet6" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet7"
                                    values={{
                                        style: (
                                            <em>
                                                <FormattedMessage id="stylebullet7" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet8"
                                    values={{
                                        style: (
                                            <em>
                                                <FormattedMessage id="stylebullet8" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet9"
                                    values={{
                                        style: (
                                            <em>
                                                <FormattedMessage id="stylebullet9" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet10"
                                    values={{
                                        style: (
                                            <em>
                                                <FormattedMessage id="stylebullet10" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet11"
                                    values={{
                                        style: (
                                            <em>
                                                <FormattedMessage id="stylebullet11" />
                                            </em>
                                        )
                                    }}
                                />
                            </li>
                        </ul>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage id="tssmp5" />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage id="tssmp6" />
                        </p>
                        <ul style={{ fontSize: 18, lineHeight: 1.5 }}>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet12"
                                    values={{
                                        link1: (
                                            <a
                                                style={{ fontStyle: "italic" }}
                                                href={formatMessage({ id: "toxFAQLink" })}
                                                target="_blank"
                                            >
                                                <FormattedMessage id="tssmbullet12link" />
                                            </a>
                                        )
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id="tssmbullet13"
                                    values={{
                                        link1: (
                                            <a href={formatMessage({ id: "rightToKnowLink" })} target="_blank">
                                                <FormattedMessage id="tssmbullet13link" />
                                            </a>
                                        )
                                    }}
                                />
                            </li>
                        </ul>
                        <Share href={href} title={title} />
                    </div>
                </div>
            </PageWrapper>
        </>
    );
}
