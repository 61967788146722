import React, { useState, useCallback, useEffect, useMemo } from "react";
import axios from "axios";
import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { languages } from "../i18n";
import { getShowTestLangUrl } from "../utilities/Utility";
import "./layout.css";

interface LanguageNavProps {
    lang: string;
    path: string;
}

export default function LanguageNav({ lang, path }: LanguageNavProps) {
    const navigate = useNavigate();
    const [showTest, setShowTest] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = (await axios.get(getShowTestLangUrl)).data as unknown;
                setShowTest(result?.toString().toLowerCase().trim() === "true");
            } catch (error) {
                console.log(error);
            }
        };
        fetchData().catch(() => {});
    }, []);

    const filtered = useMemo(() => (showTest ? languages : languages.filter((i) => !i.test)), [showTest]);

    const handleChange = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            const { value } = event.target as HTMLButtonElement;

            if (value === lang) {
                return;
            }

            localStorage.setItem("language", value);
            navigate(`/${value}/${path}`);
        },
        [navigate, lang, path]
    );

    return (
        <div>
            <Button.Group color="black" size="mini" style={{ marginRight: 10 }}>
                {filtered.map(({ value, text }) => {
                    return lang === value ? (
                        <Button
                            key={value}
                            value={value}
                            onClick={handleChange}
                            style={{ backgroundColor: "#77C4D0", fontFamily: "sans-serif" }}
                        >
                            {text}
                        </Button>
                    ) : (
                        <Button key={value} value={value} onClick={handleChange} style={{ fontFamily: "sans-serif" }}>
                            {text}
                        </Button>
                    );
                })}
            </Button.Group>
        </div>
    );
}
