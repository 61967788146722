import React, { useContext, useMemo } from "react";
import { Dropdown } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { BarChartData, ChartName, DropdownProps, LineChartData } from "../Chart";
import { ExportButton } from "../ExportButton";
import { ChartContext } from "../../context/ChartContext";
import ChartHeader from "./ChartHeader";
import { BarGraph } from "./BarGraph";
import { useCurrentPng } from "./recharts2png";
import { LineGraph } from "./LineGraph";
import ChartStats from "./ChartStats";

interface CECChartProps {
    chartName: ChartName;
    chartFileName: string;
    summaryID: string;
    footerID: string;
    barChartData: BarChartData;
    lineChartData: LineChartData;
    facilityCount: string | JSX.Element;
    pollutantCount: string | JSX.Element;
    handleOnClick: (val: { name: string }) => void;
    handleDotClick?: (val: string) => void;
    xAxisBarLabelID: string;
    yAxisLineLabelID: string;
    totalReleases: string | JSX.Element;
    chartTotal: string | JSX.Element;
    mediaTypeValue: string;
    dropdownText?: JSX.Element;
}

interface ChartSize {
    containerSize: number;
    bottom: number;
}

export default function CECChart({
    chartName,
    chartFileName,
    footerID,
    barChartData,
    lineChartData,
    facilityCount,
    pollutantCount,
    handleOnClick,
    handleDotClick,
    xAxisBarLabelID,
    yAxisLineLabelID,
    totalReleases,
    chartTotal,
    mediaTypeValue,
    dropdownText
}: CECChartProps) {
    const { formatMessage } = useIntl();
    const { dropdowns } = useContext(ChartContext);
    const { chartTypeDropdown, yearDropdown, agencyDropdown, chemicalTypeDropdown } = dropdowns;

    const [getPng, { ref, isLoading }] = useCurrentPng({ backgroundColor: "#FFF" });
    const getDropdown = ({ value, options, setOption, disabled, minWidth }: DropdownProps) => {
        return (
            <Dropdown
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: "white",
                    borderRadius: 10,
                    minWidth: minWidth || "14em"
                }}
                value={value}
                selection
                options={options.map((i) => ({ text: i.text, value: i.value }))}
                onChange={(e, data) => setOption(String(data.value))}
                disabled={disabled}
                aria-label={formatMessage({ id: value })}
            ></Dropdown>
        );
    };
    const chartSize: ChartSize = useMemo(() => {
        if (mediaTypeValue === "disposal") {
            return { containerSize: 450, bottom: 125 };
        }
        if (mediaTypeValue === "furtherManagement") {
            return { containerSize: 450, bottom: 80 };
        }
        return { containerSize: 350, bottom: 15 };
    }, [mediaTypeValue]);
    return (
        <div>
            {getDropdown(chartTypeDropdown)}
            {getDropdown(yearDropdown)}
            {getDropdown(agencyDropdown)}
            {getDropdown(chemicalTypeDropdown)}
            {dropdownText ?? null}
            <div ref={ref}>
                <div className="chartDataContainer">
                    <div>
                        <ChartHeader title={chartName} agencyID={agencyDropdown.value} />
                        {yearDropdown.value !== "allYears" ? (
                            <BarGraph
                                containerSize={{
                                    width: "90%",
                                    height: chartSize.containerSize
                                }}
                                chartSize={{
                                    width: 700,
                                    height: 650
                                }}
                                xAxisLabelProps={{
                                    offset: 0,
                                    dy: -5,
                                    labelId: xAxisBarLabelID
                                }}
                                footerLabelProps={{
                                    footerId: footerID,
                                    offset: 0,
                                    dy: 135,
                                    width: 385
                                }}
                                chartMargins={{
                                    top: 25,
                                    right: 30,
                                    left: 15,
                                    bottom: chartSize.bottom
                                }}
                                data={barChartData}
                                barCategoryGap={"10%"}
                                handleOnClick={handleOnClick}
                                mediaType={mediaTypeValue}
                                chartName={chartName}
                            />
                        ) : (
                            <LineGraph
                                containerSize={{
                                    width: "90%",
                                    height: 750
                                }}
                                chartSize={{
                                    width: 700,
                                    height: 500
                                }}
                                xAxisLabelProps={{
                                    offset: 0,
                                    dy: 20,
                                    labelId: "year"
                                }}
                                footerLabelProps={{
                                    footerId: footerID,
                                    offset: -25,
                                    dy: 135,
                                    width: 350
                                }}
                                chartMargins={{
                                    top: 40,
                                    right: 30,
                                    left: 75,
                                    bottom: 150
                                }}
                                yAxisLabelProps={{
                                    labelId: yAxisLineLabelID
                                }}
                                data={lineChartData}
                                handleDotClick={handleDotClick}
                                mediaType={mediaTypeValue}
                                chartName={chartName}
                            />
                        )}
                    </div>
                    <ChartStats
                        year={
                            yearDropdown.value === "allYears" ? formatMessage({ id: "allYears" }) : yearDropdown.value
                        }
                        stats={[
                            {
                                key: "mediaType",
                                value: formatMessage({ id: mediaTypeValue }),
                                type: "filter"
                            },
                            { key: "country", value: formatMessage({ id: agencyDropdown.value }), type: "filter" },
                            {
                                key: "pollutantType",
                                value: formatMessage({ id: chemicalTypeDropdown.value }),
                                type: "filter"
                            },
                            { key: "totalReleases", value: totalReleases, type: "result", unit: "kg" },
                            {
                                key: `${mediaTypeValue}`,
                                value: chartTotal,
                                type: "result",
                                unit: "kg"
                            },
                            { key: "facilityCount", value: facilityCount, type: "result" },
                            { key: "pollutantCount", value: pollutantCount, type: "result" }
                        ]}
                        chartName={chartName}
                    />
                </div>
            </div>
            <ExportButton getPng={getPng} chartName={chartFileName} isLoading={isLoading} />
        </div>
    );
}
