import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Image } from "semantic-ui-react";
import logo from "../images/cec-logo.webp";
import SocialRow from "./SocialRow";
import Version from "./Version";

export default function FooterDesktop() {
    const { formatMessage } = useIntl();
    return (
        <>
            <footer className="sansFont react-footer">
                <div className="container-div">
                    <div className="footer-note">
                        <FormattedMessage id="footerInfo" />{" "}
                        <a className="footer-note-link" href={formatMessage({ id: "learnMoreLink" })} target="_blank">
                            <FormattedMessage id="learnMore" />
                        </a>
                    </div>
                    <div className="footer-right-side">
                        <a href={formatMessage({ id: "cecHomePageLink" })} target="_blank">
                            <Image
                                style={{ maxWidth: "none" }}
                                height={115}
                                width={115}
                                src={logo}
                                alt="Butterflies over the earth, a brand logo incoporating CEC, CCA and CCE"
                            />
                        </a>
                        <section id="contact" />
                        <div className="footer-contact">
                            <h4 className="contact-us sansFont">
                                <FormattedMessage id="contactUs" />
                            </h4>
                            <FormattedMessage id="contactUsDescription" />
                            <span>
                                <FormattedMessage id="email" />:{" "}
                                <a className="contactUsLink" href={`mailto:${formatMessage({ id: "contactUsEmail" })}`}>
                                    <FormattedMessage id="contactUsEmail" />
                                </a>
                            </span>
                            <SocialRow />

                            <div className="copy-version">
                                <div className="copyright">
                                    <span>© {new Date().getFullYear()}</span>
                                </div>
                                <Version />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
