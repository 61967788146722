import React, { useState } from "react";
import "./layout.css";
import { Dropdown } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import I18nLink from "../i18n/Link";
import { getCBTLink, getMainDatabaseLink } from "../utilities/Utility";
import NavBarDesktop from "./NavbarDesktop";
import NavBarMobile from "./NavbarMobile";
import { useViewport } from "./hooks/useViewport";

interface NavBarChildrenProps {
    children: JSX.Element;
}

interface NavBarProps {
    children: JSX.Element;
    navbarLanguage: string;
    originalPath: string;
}

export interface MenuItem {
    hrefId: string;
    messageId: string;
    menuClassName?: string;
    pClassName?: string;
}

export const cecMenuItems: MenuItem[] = [
    { hrefId: "/scope", messageId: "tsScopeMethod" },
    { hrefId: "/terminology", messageId: "glossarytitle" },
    { hrefId: "/features", messageId: "featuresThreeNA" },
    { hrefId: "/prtr", messageId: "prtrReportReqs" }
];

export const menuItems: MenuItem[] = [
    { hrefId: "learnMoreLink", messageId: "about" },
    { hrefId: "giveFeedbackLink", messageId: "giveFeedback", pClassName: "newItem" },
    { hrefId: "actionPlanLink", messageId: "actionPlan" },
    { hrefId: "tsoReportLink", messageId: "tsoReport" }
];

export const getTsoMenuItems = (culture: string) => {
    return [
        {
            hrefId: getMainDatabaseLink(culture),
            messageId: "mainDatabaseLink",
            menuClassName: "tsoLink"
        },
        { hrefId: getCBTLink(culture), messageId: "cbtLink", menuClassName: "tsoLink" }
    ];
};

export const getMenuItem = (
    hrefId: string,
    messageId: string,
    index: number,
    menuClassName?: string,
    pClassName?: string,
    target?: string
) => {
    return (
        <Dropdown.Item key={index} as="a" href={hrefId} target={target} className={`${menuClassName ?? ""}`}>
            {getFormattedParagraph(messageId, `colorButton ${pClassName ?? ""}`)}
        </Dropdown.Item>
    );
};

export const getCECMenuItem = (to: string, messageId: string, index: number) => {
    return (
        <Dropdown.Item style={{ paddingLeft: 30 }} as={I18nLink} to={to} key={index}>
            {getFormattedParagraph(messageId, "colorButton")}
        </Dropdown.Item>
    );
};

export const getFormattedParagraph = (messageId: string, pClassName?: string) => {
    return (
        <p className={pClassName}>
            <FormattedMessage id={messageId} />
        </p>
    );
};

const NavBarChildren = ({ children }: NavBarChildrenProps) => <div>{children}</div>;

export default function NavBar({ children, navbarLanguage, originalPath }: NavBarProps) {
    const [visible, setVisible] = useState(false);
    const { width, breakpoint } = useViewport();

    return (
        <div>
            <div>
                {width < breakpoint ? (
                    <NavBarMobile
                        onPusherClick={() => {
                            if (visible) {
                                setVisible(false);
                            }
                        }}
                        onToggle={() => setVisible(!visible)}
                        visible={visible}
                        lang={navbarLanguage}
                        path={originalPath}
                    >
                        <NavBarChildren>{children}</NavBarChildren>
                    </NavBarMobile>
                ) : (
                    <>
                        <NavBarDesktop path={originalPath} lang={navbarLanguage} />
                        <NavBarChildren>{children}</NavBarChildren>
                    </>
                )}
            </div>
        </div>
    );
}
