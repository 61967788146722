/* eslint-disable import/no-restricted-paths */
import React from "react";
import "../components/layout.css";
import { Divider, Container, Icon } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import TakingStock from "../components/TakingStock";
import Reports from "../components/Reports";
import CrossBorderTransfers from "../components/CrossBorderTransfers/CrossBorderTransfersData";
import OrangeIconSet from "../components/OrangeIconSet/OrangeIconSet";
import PageWrapper from "./PageWrapper";
import "./index.css";

interface IndexPageProps {
    lang: string;
}

export default function IndexPage({ lang }: IndexPageProps) {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: "tsoTitle" });
    const tsoReportLink = formatMessage({ id: "tsoReportLink" });
    return (
        <>
            <PageWrapper
                lang={lang}
                title={title}
                originalPath={""}
                keywords={["Commission", "Environmental", "Cooperation"]}
            >
                <>
                    <TakingStock />
                    <div id="reports">
                        <Reports />
                    </div>
                    <div style={{ backgroundColor: "#77C4D0", paddingTop: 50 }}>
                        <Container>
                            <CrossBorderTransfers />
                        </Container>
                    </div>
                    <div
                        style={{
                            paddingBottom: 60,
                            paddingTop: 30,
                            backgroundColor: "#D0CECF"
                        }}
                    >
                        <Container>
                            <Divider
                                style={{
                                    borderWidth: 3,
                                    borderColor: "black",
                                    width: 300,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginBottom: 50
                                }}
                            />
                            <div>
                                <p className="footerMessage">
                                    <FormattedMessage id="footerCallToAction" />
                                </p>
                                <p className="footerMessage footerSecondary">
                                    <FormattedMessage id="footerSecondary" />
                                </p>
                            </div>
                        </Container>
                    </div>
                    <div style={{ backgroundColor: "#D0CECF", paddingBottom: 60 }}>
                        <Container text textAlign="center" style={{ backgroundColor: "#D0CECF", marginBottom: -10 }}>
                            <OrangeIconSet />
                        </Container>
                        <p
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: 20,
                                fontWeight: "bolder",
                                marginBottom: 50,
                                textAlign: "center"
                            }}
                        >
                            <FormattedMessage id="buttonHeader" />
                        </p>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: "#F47720",
                                padding: 10,
                                borderRadius: 30,
                                width: 275
                            }}
                        >
                            <div>
                                <a href={tsoReportLink} target="_blank">
                                    <Icon
                                        style={{ color: "white", fontWeight: "bold" }}
                                        className="colorButton"
                                        name="download"
                                    />
                                </a>
                            </div>
                            <div>
                                <a href={tsoReportLink} target="_blank">
                                    <p className="iconButton" style={{ color: "white", fontWeight: "bold" }}>
                                        <FormattedMessage id="tsoReport" />
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            </PageWrapper>
        </>
    );
}
