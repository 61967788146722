import React from "react";
import { useIntl } from "react-intl";
import { Button } from "semantic-ui-react";

export default function SocialRow() {
    const { formatMessage } = useIntl();
    const buttonStyle: React.CSSProperties = {
        width: 35,
        height: 35,
        fontSize: 16,
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        color: "#03132a",
        backgroundColor: "white"
    };
    return (
        <div className="social-row">
            <Button
                style={buttonStyle}
                target="_blank"
                icon="twitter"
                href={formatMessage({ id: "twitterLink" })}
            ></Button>
            <Button
                style={buttonStyle}
                target="_blank"
                icon="linkedin in"
                href={formatMessage({ id: "linkedinLink" })}
            ></Button>
            <Button
                style={buttonStyle}
                target="_blank"
                icon="facebook f"
                href={formatMessage({ id: "facebookLink" })}
            ></Button>
            <Button
                style={buttonStyle}
                target="_blank"
                icon="instagram"
                href={formatMessage({ id: "instagramLink" })}
            ></Button>
            <Button
                style={buttonStyle}
                target="_blank"
                icon="youtube"
                href={formatMessage({ id: "youtubeLink" })}
            ></Button>
            <Button
                style={buttonStyle}
                target="_blank"
                icon="medium m"
                href={formatMessage({ id: "mediumLink" })}
            ></Button>
            <Button
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    padding: "8px 32px",
                    color: "#001738",
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontSize: "13.6px",
                    lineHeight: "23.8px",
                    backgroundColor: "white",
                    marginTop: 10,
                    height: 35
                }}
                href={formatMessage({ id: "newsSignUpLink" })}
            >
                {formatMessage({ id: "subscribeBtn" })}
            </Button>
        </div>
    );
}
