module.exports = {
    about: "Acerca",
    actionPlan: "Plan de acción",
    actionPlanLink: "http://www.cec.org/es/publications/plan-de-accion-para-fomentar-la-comparabilidad-de-los-registros-de-emisiones-y-transferencias-de-contaminantes-retc-de-america-del-norte/",
    allOtherPollutants: "Resto de los contaminantes",
    allOtherSectors: "Resto de los sectores",
    allPollutantTypes: "Todas las clases de contaminantes",
    allYears: "Todos los años",
    andCompounds: "* y sus compuestos",
    associated: "Ejemplos de contaminantes asociados:",
    barium: "Bario*",
    boldlist1: "emisiones y disposición en sitio",
    boldp2: "eliminación o disposición final fuera de sitio",
    boldp4: "transferencias para otro modo de gestión",
    buttonHeader: "Explore los análisis de datos RETC de América del Norte y la información relacionada a través de la serie de informes En balance.",
    canada: "Canadá",
    CanadaNAICSLink: "https://www.statcan.gc.ca/en/concepts/industry",
    canadaToUS: "Total Canadá a los EE-UU",
    cbtAction: "Elige un año para ver las cantidades transferidas entre países.",
    cbtLink: "Explore las transferencias transfronterizas",
    cbtTitle: "Transferencias transfronterizas",
    cbtTotal: "Transferencias transfronterizas totales:",
    cecHomePageLink: "http://www.cec.org/es",
    chromium: "Cromo*",
    clickBarIndustryExplore: "Haga clic en una barra para explorar los datos de ese sector industrial en la base de datos.",
    clickBarPollutantExplore: "Haga clic en una barra para explorar los datos de ese contaminante en la base de datos.",
    clickDotIndustryExplore: "Haga clic en un punto para explorar los datos de ese sector industrial en la base de datos.",
    clickDotPollutantExplore: "Haga clic en un punto para explorar los datos de ese contaminante en la base de datos.",
    contact: "Contáctenos",
    contactUs: "CONTÁCTENOS",
    contactUsDescription: "Iniciativa del Registro de Emisiones y Transferencia de Contaminantes (RETC) de América del Norte",
    contactUsEmail: "NAPRTR@cec.org",
    country: "País",
    devReproToxins: "Sustancias tóxicas que afectan el desarrollo o la reproducción",
    disposal: "Transferencias para disposición final*",
    disposalFooter: "* ’Transferencias para disposición final’ incluye las siguientes subcategorías:",
    disposalSubCatLandApplication: "c) para aplicación en suelos",
    disposalSubCatLandfill: "a) a relleno sanitario o embalse superficial",
    disposalSubCatOther: "f) para otro tipo de disposición final (desconocido)",
    disposalSubCatStabilization: "d) para estabilización o tratamiento previo a la disposición final",
    disposalSubCatStorage: "e) a almacenamiento previo a la disposición final",
    disposalSubCatUndergroundInjection: "b) para inyección subterránea",
    email: "Correo-e",
    exploreNewTSReport: "¡Explore el nuevo informe En balance!",
    export: "Exportar",
    facebookLink: "https://www.facebook.com/CCAconecta",
    facebookShareLink: "https://www.facebook.com/sharer/sharer.php?u=",
    facilityCount: "Número de instalaciones",
    featuresp1: "En balance se elabora con base en la información proporcionada por los tres programas nacionales de registro de emisiones y transferencias de contaminantes (RETC) de América del Norte. El RETC de cada uno de los tres países ha formulado su propia lista de contaminantes, sectores industriales y requisitos de registro. El cuadro siguiente compara características de los tres sistemas RETC.",
    featurestabler1c1: "Característica",
    featurestabler1c2: "Inventario Nacional de Emisiones de Contaminantes de Canadá(Canadian National Pollutant Release Inventory, NPRI)",
    featurestabler1c3: "Registro de Emisiones y Transferencia de Contaminantes de México (RETC)",
    featurestabler1c4: "Inventario de Emisiones Tóxicas de Estados Unidos(US Toxics Release Inventory, TRI)",
    featurestabler2c1: "Primer año de registro obligatorio",
    featurestabler3c1: "Actividades industriales o sectores cubiertos",
    featurestabler3c2: "Todo establecimiento que produzca o utilice una sustancia enlistada, excepto para actividades como investigación, reparación, venta al menudeo, agricultura y silvicultura. Toda instalación que emita al aire contaminantes atmosféricos de criterio (CAC) en cantidades específicas.",
    featurestabler3c3: "Establecimientos en once sectores bajo jurisdicción federal (en términos de emisiones atmosféricas): petróleo, industria química y petroquímica, pinturas y tintas, metalurgia (hierro y acero), fabricación de automóviles, celulosa y papel, cemento y cal, asbesto, vidrio, centrales eléctricas y manejo de desechos peligrosos. Otras plantas con actividades específicas sujetas a jurisdicción federal - por ejemplo:",
    featurestabler3c3bullet1: "Grandes generadores (10 toneladas o más) de desechos peligrosos (si los desechos transferidos contienen sustancias RETC en cantidades igual al umbral de declaración)",
    featurestabler3c3bullet2: "Las instalaciones que descargan aguas residuales en los cuerpos de agua nacionales (si las aguas residuales contienen sustancias RETC en cantidades iguales o mayores que el umbral de declaración)",
    featurestabler3c4: "Plantas manufactureras, instalaciones federales, centrales eléctricas (a base de petróleo o carbón), minería de carbón y metales, plantas de manejo de desechos peligrosos y recuperación de solventes, mayoristas de sustancias químicas, y terminales de petróleo a granel.",
    featurestabler4c1: "Número de contaminantes sujetos a registro",
    featurestabler4c2: "Más de 320 contaminantes (o grupos de contaminantes).",
    featurestabler4c3: "200 contaminantes/grupos de contaminantes (desde 2014).",
    featurestabler4c4: "Más de 700 contaminantes y 33 categorías de contaminantes.",
    featurestabler5c1: "Umbrales de empleo",
    featurestabler5c2: "Por lo general, diez empleados o más. Para ciertas actividades, como incineración de desechos y tratamiento de aguas residuales, el umbral de diez empleados no se aplica.",
    featurestabler5c3: "Sin umbral de empleo.",
    featurestabler5c4: "Diez o más empleados de tiempo completo o su equivalente en horas.",
    featurestabler6c1: "Umbrales de “actividad“ (fabricación, proceso o uso de otra forma) y de “emisiones“ de la sustancia",
    featurestabler6c2: "Umbrales de “actividad” de 10,000 kg para la mayoría de las sustancias químicas. Umbrales más bajos para algunos contaminantes, como sustancias tóxicas persistentes y bioacumulables (STPB), hidrocarburos aromáticos policíclicos, dioxinas y furanos, y contaminantes atmosféricos de criterio.",
    featurestabler6c3: "Umbrales de “emisión” y de “actividad” para cada contaminante (los establecimientos deben informar si alcanzan o exceden cualquiera de los umbrales). Salvo para los gases de efecto invernadero, los umbrales de emisión varían de 1 a 1,000 kg y los de “actividad”, entre 5 y 5,000 kg. Todas las emisiones de bifenilos policlorados y hexafluoruro de azufre, así como toda emisión o actividad de dioxinas y furanos, deben registrarse.",
    featurestabler6c4: "Umbrales de “actividad” de 25,000 lb (alrededor de 11,340 kg), con umbral de “otros usos” de 10,000 lb (alrededor de 5,000 kg). Umbrales más bajos para algunas sustancias, como las tóxicas persistentes y bioacumulables (STPB) y dioxinas y furanos.",
    featurestabler7c1: "Tipos de emisiones y transferencias cubiertas",
    featurestabler7c2: "Emisiones en sitio al aire, agua y suelo, y disposición final en sitio (incluida la inyección subterránea). Transferencias fuera de sitio para disposición final o para tratamiento previo a la disposición final (incluidas descargas al alcantarillado), reciclaje y recuperación de energía.",
    featurestabler7c3: "Emisiones en sitio al aire, agua y suelo. Transferencias fuera de sitio para disposición final, reciclaje, reutilización, recuperación de energía, tratamiento, coprocesado (insumo en otro proceso de producción) y descargas al alcantarillado.",
    featurestabler7c4: "Emisiones en sitio al aire, agua y suelo, así como inyección subterránea en sitio. Transferencias fuera de sitio para disposición final, reciclaje, recuperación de energía, tratamiento y descargas al alcantarillado.",
    featuresThreeNA: "Características de los sistemas RETC de América del Norte",
    filtersSelected: "Filtros seleccionados",
    footerCallToAction: "¿Desea saber más?",
    footerInfo: "La iniciativa del Registro de Emisiones y Transferencias de Contaminantes (RETC) de América del Norte de la CCA promueve el acceso público a datos e información reportados por plantas industriales en Canadá, Estados Unidos y México para mejorar la comprensión de las fuentes y el manejo de contaminantes de interés común y respaldar decisiones relacionadas con la prevención de la contaminación y la sostenibilidad.",
    footerSecondary: "Explore las emisiones y transferencias informadas por aproximadamente 40,000 instalaciones industriales en América del Norte, por año, contaminante, instalación, sector, país o región (incluidas las transferencias transfronterizas).",
    for: "para",
    furtherManagement: "Transferencias para otro modo de gestión**",
    furtherManagementEnergy: "c) para recuperación de energía",
    furtherManagementSewage: "b) para alcantarillado/tratamiento",
    furtherManagementTreatment: "a) para tratamiento",
    ghgLink: "https://www.epa.gov/ghgreporting",
    giveFeedback: "Denos su opinión",
    giveFeedbackLink: "https://docs.google.com/forms/d/e/1FAIpQLSddTT6gZXPtyMT7ZHsXxzk3J7fZryep8VKHG00XqvB2EqcdlQ/viewform",
    glossarybullet1: "Emisiones al aire",
    glossarybullet2: "Descargas en un cuerpo de agua superficial",
    glossarybullet3: "Emisiones por inyección subterránea",
    glossarybullet4: "Emisiones o eliminación en el suelo",
    glossarylist1: "Las {style} ocurren en las premisas de los establecimientos industriales, e incluyen:",
    glossaryp1: "{style} emplea las categorías siguientes para presentar información sobre los contaminantes emitidos y transferidos.",
    glossaryp2: "La {style} corresponde al envío de contaminantes a instalaciones fuera de sitio para su disposición final (por ejemplo, en un relleno sanitario o embalse superficial; para inyección subterránea).",
    glossaryp3: "Las {style} corresponden a las sustancias que se envían fuera de sitio para su reciclaje.",
    glossaryp4: "Las {style} incluyen los contaminantes (excepto metales)* enviados a instalaciones fuera de sitio para tratamiento o recuperación de energía, o bien descargados al alcantarillado.",
    glossaryp5: "{style} Las cantidades reportadas de metales que se transfieren fuera de sitio para tratamiento o recuperación de energía, o que se descargan al alcantarillado, se incluyen en la categoría eliminación o disposición final fuera de sitio, ya que estos metales pueden captarse y separarse del resto de los residuos para su disposición en rellenos sanitarios u otros métodos de disposición final. Este enfoque reconoce la naturaleza física de los metales y el hecho de que estos no se destruyen al ser sometidos a procesos de tratamiento.",
    glossaryp6: "{style} Puesto que esta terminología es especifica de En balance, los términos “emisiones” y “transferencias”, según se definen aquí, pueden diferir del uso o sentido que adoptan en los informes NPRI, TRI y RETC.",
    glossarytitle: "Terminología",
    home: "Página inicial",
    hoverText1: "Las diferencias entre los requisitos de informes nacionales para contaminantes y sectores deben tenerse en cuenta al interpretar los datos del RETC de América del Norte. Para más información, véase “Comprensión de En balance.”",
    hoverText2: "Cada sistema RETC cubre una lista de sustancias específicas: el NPRI abarca casi 350 contaminantes; el TRI alrededor de 650; y el RETC incluye 200.",
    hydrochloricAcid: "Ácido clorhídrico",
    impconNaics1part1: "El Sistema de Clasificación Industrial de América del Norte (SCIAN) fue desarrollado por las agencias estadísticas de Canadá, México y los Estados Unidos en 1997. La estructura del SCIAN es jerárquica y consta de un código de seis dígitos (los primeros dos dígitos designan el sector, el tercero designa el subsector, y así sucesivamente). Los códigos SCIAN se incorporaron a los informes RETC en 2006 y los usuarios de En balance en línea pueden consultar los datos en los niveles SCIAN-3, SCIAN-4 y SCIAN-5.",
    impconNaics1Title: "Códigos del Sistema de Clasificación Industrial de América del Norte (SCIAN)",
    impconNaics2part1: "Los datos de los RETC de América del Norte reflejan el tamaño y la composición de la base industrial de cada país, así como las diferencias en los requisitos de registro de los RETC nacionales en relación con los sectores y actividades industriales:",
    impconNaics2part1ca1: "Todas las instalaciones que cumplen con los umbrales y los requisitos de registro deben informar, con algunas excepciones (p. ej., instalaciones de investigación).",
    impconNaics2part1mx1: "Once sectores bajo jurisdicción federal, así como instalaciones en otros sectores que participan en actividades reguladas por el gobierno federal (por ejemplo, instalaciones que manejan desechos peligrosos o descargan contaminantes en cuerpos de agua nacionales) deben declarar.",
    impconNaics2part1us1: "Las instalaciones federales, la mayoría de las instalaciones de fabricación y las industrias que dan servicio a las instalaciones de fabricación (p. ej., servicios públicos de electricidad, instalaciones de gestión de residuos peligrosos) deben informar. Unos pocos sectores basados ​​en recursos (por ejemplo, extracción de petróleo y gas) están exentos.",
    impconNaics2part2: "Con pocas excepciones, solo las instalaciones canadienses y estadounidenses que alcanzan el umbral de empleados (generalmente 10 empleados a tiempo completo o el equivalente en horas trabajadas) están sujetas a informes de RETC.",
    impconNaics2Title: "Requisitos de registro de los RETC relativos a los sectores industriales",
    impconTpna1part1: "Cada país tiene requisitos de notificación únicos en relación con los contaminantes:",
    impconTpna1part1ca: "El NPRI canadiense:",
    impconTpna1part1ca1: "más de 320 sustancias",
    impconTpna1part1mx: "El RETC mexicano:",
    impconTpna1part1mx1: "200 sustancias (antes de 2014 eran 104)",
    impconTpna1part1us: "El TRI de Estados Unidos:",
    impconTpna1part1us1: "más de 700 sustancias (y 33 categorías químicas)",
    impconTpna1part2: "Cada programa también incluye umbrales de registro de contaminantes, con algunos contaminantes que tienen umbrales más bajos debido a su mayor potencial para dañar la salud humana y/o el medio ambiente.",
    impconTpna1part3: "Para ver la lista de sustancias reportadas a los RETC de América del Norte:",
    impconTpna1Title: "Requisitos de registro de los RETC relativos a los contaminantes",
    impconTpna2part1: "Algunas sustancias de En balance se clasifican en una o más de las siguientes categorías:",
    impconTpna2part2: "Nota: Los datos de emisión de contaminantes atmosféricos de criterio (CAC) y gases de efecto invernadero (GEI) no se incluyen en En balance debido a las diferencias en los requisitos de registro de los RETC nacionales para estos contaminantes.",
    impconTpna2Title: "Clases de contaminantes en En balance",
    impconTpna3part1: "Además de la cantidad emitida o transferida, se deben considerar otros factores cuando se trata de evaluar si una sustancia representa un riesgo para la salud humana o el medio ambiente. Estos incluyen la toxicidad inherente del contaminante, su potencial para persistir en el medio ambiente o para modificarlo de alguna manera, el medio de emisión, el nivel de exposición; etc.",
    impconTpna3part2: "Potencial de Equivalencia Tóxica (PET):",
    impconTpna3part3: "Para algunos contaminantes emitidos al aire o al agua,",
    impconTpna3part4: "En balance también proporciona valores PET, que indican el potencial de una sustancia para causar cáncer u otros efectos (no cancerígenos).",
    impconTpna3Title: "Factores a considerar cuando se utilizan datos de los RETC para evaluar el riesgo",
    industryExportTitle: "PrincipalesSectores",
    industrySummary: "Total (todas las industrias informantes):",
    industryText: "Los sectores industriales se muestran en el nivel SCIAN-4.",
    industryTitle: "Principales sectores (por cantidad)",
    ineccLink: "https://www.gob.mx/inecc/acciones-y-programas/inventario-nacional-de-emisiones-de-gases-y-compuestos-de-efecto-invernadero",
    inemLink: "https://www.gob.mx/semarnat/acciones-y-programas/inventario-nacional-de-emisiones-de-contaminantes-criterio-inem",
    instagramLink: "https://www.instagram.com/ccaamericadelnorte/",
    interactiveGraphicText: "Haga clic en cualquiera de las categorías en la imagen a continuación, luego use los filtros en los gráficos asociados para explorar algunos de los datos disponibles para aproximadamente 40,000 establecimientos en la región.",
    interactiveGraphicTitle: "¿Quiere saber más sobre las emisiones y transferencias de contaminantes industriales en América del Norte?",
    introTextOne: "La fabricación de productos electrónicos puede generar grandes cantidades de residuos potencialmente peligrosos.",
    introTextThree: "Los contaminantes emitidos al agua por el sector de producción y procesamiento de alimentos pueden contribuir a la eutrofización o disminución del oxígeno en los cuerpos de agua.",
    introTextTwo: "Las emisiones atribuibles a la quema de combustibles fósiles pueden generar gases ácidos y ocasionar problemas respiratorios.",
    introTitleOne: "productos electrónicos",
    introTitleThree: "producción de alimentos",
    introTitleTwo: "combustibles fósiles",
    kilograms: "kilogramos",
    knownOrSusCarc: "Carcinógeno conocido o presunto",
    lead: "Plomo*",
    learnMore: "Obtener más información.",
    learnMoreLink: "http://www.cec.org/es/contaminantes/rastreo-de-emisiones-y-transferencias-de-contaminantes-en-america-del-norte/",
    linkedinLink: "https://www.linkedin.com/company/commission-for-environmental-cooperation-of-north-america",
    linkedinShareLink: "https://www.linkedin.com/shareArticle?mini=true&url=",
    liststyle1: "En balance",
    loading: "Cargando...",
    mailShareLink: "mailto:?subject=",
    mainDatabaseLink: "Consulte la base de datos principal",
    manganese: "Manganeso*",
    mapLink: "Mape los datos",
    mediaType: "Tipo de emisión o transferencia",
    mediumLink: "https://medium.com/@cecweb",
    mercury: "Mercurio*",
    metals: "Metales",
    mexico: "México",
    mexicoToUS: "Total México a los EE-UU",
    mexNAICSLink: "http://www.inegi.org.mx/app/biblioteca/ficha.html?upc=702825099701",
    neiLink: "https://www.epa.gov/air-emissions-inventories/2014-national-emissions-inventory-nei-data",
    newsSignUpLink: "http://www.cec.org/es/suscribase/",
    newTSReportURL: "http://www.cec.org/tsreports/es/",
    nitricAcid: "Ácido nítrico / Compuestos nitrados",
    northAmerica: "América del Norte",
    northAmericanPRTRDB: "Base de datos de RETC de América del Norte",
    npri: "NPRI",
    npriLink: "https://www.canada.ca/en/services/environment/pollution-waste-management/national-pollutant-release-inventory.html",
    openDataCanadaLink: "https://open.canada.ca/data/en/dataset/a8ba14b7-7f23-462a-bdbb-83b0ef629823",
    persBioToxic: "Sustancias tóxicas persistentes y bioacumulables (STPB)",
    pollutantCount: "Número de contaminantes",
    pollutantType: "Clase de contaminante",
    prtrIntro: "Un registro de emisiones y transferencias de contaminantes (RETC) aporta datos anuales de los contaminantes generados por establecimientos o plantas industriales, ya sea emitidos en sitio —liberados al aire, descargados en el agua, eliminados en el suelo o destinados a inyección subterránea—, o bien enviados fuera de sitio para su reciclaje, tratamiento o eliminación (disposición final). La iniciativa Registro de Emisiones y Transferencias de Contaminantes (RETC) de América del Norte de la CCA fomenta el acceso de la ciudadanía a los datos registrados por instalaciones industriales de Canadá, Estados Unidos y México, con el objetivo de contribuir a un mejor conocimiento de las fuentes y el manejo de los contaminantes de preocupación común en la región. Los datos que incluye En balance se derivan de los informes que las instalaciones industriales presentan a los programas RETC nacionales de los tres países: el {link1} (National Pollutant Release Inventory, NPRI) de Canadá, el {link2} (Toxics Release Inventory, TRI) de Estados Unidos y el {link3} de México. Nuestra labor se centra en agregar valor a los datos RETC nacionales mediante su integración, análisis y divulgación.",
    prtrIntrolink1: "Inventario Nacional de Emisiones de Contaminantes",
    prtrIntrolink2: "Inventario de Emisiones Tóxicas",
    prtrIntrolink3: "Registro de Emisiones y Transferencia de Contaminantes (RETC)",
    prtrReportReqs: "Los RETC y sus requisitos generales de registro",
    prtrtab1bullet3: "Carcinógenos conocidos o presuntos, con base en la clasificación de la Agencia Internacional para la Investigación sobre el Cáncer (International Agency for Research on Cancer, IARC) de la Organización Mundial de la Salud (OMS) y la lista de la Propuesta 65 de la Oficina de Salud Ambiental y Evaluación de Riesgo del estado de California;",
    prtrtab1bullet4: "Sustancias que causan desórdenes en el desarrollo y la reproducción, de acuerdo con la lista de la Propuesta 65 de California;",
    prtrtab1bullet5: "Sustancias tóxicas persistentes y bioacumulables (STPB), cuyas características hacen de ellas una amenaza para la salud humana y el medio ambiente a largo plazo, incluso en cantidades menores;",
    prtrtab1bullet6: "Metales, que si bien están presentes en el medio ambiente de modo natural, registran mayores concentraciones en ciertos entornos a raíz de actividades humanas como la minería y la fundición. La toxicidad de algunos metales y sus compuestos depende en parte de las formas que adoptan en el medio ambiente.",
    prtrtab1link: "Descargar lista",
    prtrtab1p1: "Los contaminantes sujetos a registro en cada sistema RETC nacional lo están porque cumplen ciertos criterios en lo que a su toxicidad química se refiere y porque representan riesgos para la salud humana y el medio ambiente. Cada sistema RETC cubre una lista de sustancias específicas: el NPRI abarca más de 320 contaminantes; el TRI más de 700; y desde 2014, el RETC incluye 200. Cada sistema RETC también cuenta con umbrales de registro de contaminantes dependiendo de si la sustancia se fabrica, procesa o utiliza de otro modo (MPO) o se emita. Ciertos contaminantes observan umbrales de registro inferiores debido a que tienen un mayor potencial de riesgo para la salud humana y el medio ambiente.",
    prtrtab1p2: "Más informacióne: {featuresLink}",
    prtrtab1p3: "Para ver la lista de contaminantes registrados en los RETC de América del Norte, consúltese: {link1}.",
    prtrtab1p3link: "[Archivo de Excel]",
    prtrtab1p4: "Con el fin de detallar aún más la información sobre los contaminantes incluidos en los RETC, el informe En balance y su base de datos en línea establecieron asimismo las siguientes categorías",
    prtrtab1title: "¿Qué contaminantes han de incluirse en los registros?",
    prtrtab2p1: "El potencial de equivalencia tóxica (PET) indica el riesgo relativo para la salud humana asociado con una emisión de una unidad del contaminante en comparación con el riesgo planteado por la emisión unitaria de una sustancia de referencia. El PET se calcula empleando el modelo CalTox, desarrollado por las dependencias de regulación de California, y es uno de los muchos instrumentos de análisis de que se dispone hoy día. Es importante observar que cada instrumento de análisis opera con una serie de supuestos y, por tanto, puede generar resultados diferentes. Los PET se tomaron de Scorecard. La información de evaluación de riesgos utilizada en En balance se revisa y actualiza periódicamente.",
    prtrtab2p2: "Los lectores deben tomar en cuenta, asimismo, que el análisis PET resulta limitado en cuanto a que las emisiones no implican correlación directa con la exposición o los niveles de riesgo reales, además de que no todas las sustancias tienen asignado un PET (hay casos en los que no se cuenta con información sobre su toxicidad o potencial de exposición). No debe suponerse que los contaminantes sin clasificación por PET, así como tampoco otros tipos de emisiones no clasificadas por PET (por ejemplo, las emisiones al suelo), carecen de riesgo.",
    prtrtab2p3: "Se dispone de valores PET únicamente para las emisiones al aire y el agua. La sustancia de referencia para carcinógenos es el benceno, como el tolueno lo es para las sustancias no-cancerígenas. En el informe En balance y en su base de datos en línea la ponderación PET se multiplica por la cantidad de las emisiones a fin de obtener una clasificación para cada contaminante.",
    prtrtab2title: "Potencial de equivalencia tóxica para las emisiones al aire y agua",
    prtrtab3bullet1: "National Pollutant Release Inventory (NPRI), Canadá",
    prtrtab3bullet2: "National Emissions Inventory (NEI), EE-UU",
    prtrtab3bullet3: "Inventario Nacional de Emisiones de Contaminantes Criterio (INEM), México",
    prtrtab3bullet4: "Greenhouse Gas Reporting Program, Canadá",
    prtrtab3bullet5: "Greenhouse Gas Reporting Program, EE-UU",
    prtrtab3bullet6: "Registro de Emisiones y Transferencia de Contaminantes (RETC), México",
    prtrtab3bullet7: "Inventario Nacional de Emisiones de Gases y Compuestos de Efecto Invernadero, México",
    prtrtab3list1: "Contaminantes atmosféricos de criterio:",
    prtrtab3list2: "Gases de efecto invernadero:",
    prtrtab3p1: "En balance no incluye datos correspondientes a emisiones de contaminantes atmosféricos de criterio (CAC) ni de gases de efecto invernadero (GEI), debido a las diferencias que prevalecen en los requisitos de registro entre los sistemas RETC nacionales para estos contaminantes. Los CAC (monóxido de carbono, óxidos de nitrógeno, partículas, óxidos de azufre y compuestos orgánicos volátiles) son un grupo de sustancias químicas asociadas con efectos ambientales como el esmog, la lluvia ácida y la neblina regional, además de efectos en la salud como diversas enfermedades respiratorias. Las principales fuentes de CAC son la quema de combustibles fósiles, la extracción de recursos naturales y diversas actividades manufactureras. Los gases de efecto invernadero (GEI) —objeto del Protocolo de Kioto, en vigor desde 2005— contribuyen al cambio climático al capturar el calor al interior de la atmósfera terrestre. Entre los principales GEI se incluyen el dióxido de carbono, el metano, el óxido nitroso y tres grupos de gases fluorinados. Algunas de las principales fuentes antropogénicas de GEI son la quema de combustibles fósiles, la deforestación y las actividades agrícolas. Los CAC se registran en el NPRI de Canadá y los GEI se informan en el RETC de México, pero ninguno de esos dos grupos está incluido en los registros del TRI de Estados Unidos. Sin embargo, hay otras fuentes de información sobre las emisiones de estos contaminantes en los tres países:",
    prtrtab3title: "Registro de contaminantes atmosféricos de criterio y gases de efecto invernadero",
    prtrtab4bullet1: "En Canadá, todos los establecimientos industriales que alcanzan los umbrales y los requisitos respectivos deben presentar registros al NPRI, salvo los de algunos sectores de aprovechamiento de recursos naturales y los que se dedican a ciertas actividades, como los laboratorios de investigación.",
    prtrtab4bullet2: "En Estados Unidos, el TRI exige que presenten registros los establecimientos federales, la mayor parte de las plantas manufactureras y las instalaciones que prestan servicios a plantas manufactureras (por ejemplo, centrales eléctricas y plantas de manejo de desechos peligrosos). Algunos sectores de recursos primarios, como la extracción de gas y petróleo, están exentos de presentar informes.",
    prtrtab4bullet3: "En México, todos los sectores industriales bajo jurisdicción federal están obligados a presentar registros al RETC, al igual que las plantas de otros sectores que realizan actividades sujetas a reglamentación federal. Entre dichas instalaciones figuran las que transfieren desechos peligrosos o realizan descargas en los cuerpos de agua nacionales.",
    prtrtab4p1: "Cada uno de los países exige que presenten registros los establecimientos de sectores industriales específicos o que desempeñen determinadas actividades industriales.",
    prtrtab4title: "¿Qué industrias deben presentar registros?",
    prtrtab5p1: "Canadá, Estados Unidos y México adoptaron el Sistema de Clasificación Industrial de América del Norte (SCIAN) en cuyos códigos se basan las categorías de las actividades industriales de los establecimientos. Los códigos SCIAN se establecieron en 1997, y a partir de 2006 se incorporaron a los registros RETC en sustitución de los códigos de clasificación industrial estándar empleados por cada uno de los países. Aunque existen algunas diferencias entre los tres países en cuanto a los códigos y las categorizaciones para los subsectores, el desagregado de los sectores industriales en categorías generales es el mismo en toda la región. Para obtener mayor información sobre el uso del código SCIAN en cada uno de los países, consúltese:",
    prtrtab5p2: "Los requisitos de registro de emisiones y transferencias de contaminantes se basan en parte en la actividad industrial realizada en el establecimiento industrial y no únicamente en el código industrial que le ha sido asignado. Es por ello que no todas las plantas de un sector determinado están necesariamente obligadas a presentar registros. Por ejemplo, en el sector económico que comprende el lavado en seco únicamente deben presentar informes las plantas que efectúan el proceso mismo de lavado y no los puntos de recepción y entrega de la ropa. Otro ejemplo es el de una planta de procesamiento de alimentos que por poseer su propia planta de generación eléctrica deberá presentar registros.",
    prtrtab5tabletitle1: "Código SCIAN",
    prtrtab5tabletitle2: "Industria",
    prtrtab5title: "Sistema de Clasificación Industrial de América del Norte",
    prtrtab6p1: "Tanto el NPRI como el TRI disponen de un umbral de empleo que en general corresponde al equivalente de diez empleados de tiempo completo (con excepciones para algunos contaminantes o cierto tipo de plantas). El RETC de México no tiene un umbral por número de trabajadores. En los sitios web de los respectivos RETC se dispone de información adicional con indicaciones sobre la presentación de registros.",
    prtrtab6title: "Umbrales de empleo",
    prtrtabler1: "Agricultura, ganadería, aprovechamiento forestal, pesca y caza",
    prtrtabler10: "Servicios financieros y de seguros",
    prtrtabler11: "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles",
    prtrtabler12: "Servicios profesionales, científicos y técnicos",
    prtrtabler13: "Dirección de corporativos y empresas",
    prtrtabler14: "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
    prtrtabler15: "Servicios educativos",
    prtrtabler16: "Servicios de salud y de asistencia social",
    prtrtabler17: "Servicios de esparcimiento, culturales y deportivos y otros servicios recreativos",
    prtrtabler18: "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
    prtrtabler19: "Otros servicios excepto actividades del gobierno",
    prtrtabler2: "Minería y extracción de petróleo y gas",
    prtrtabler20: "Actividades del gobierno",
    prtrtabler3: "Electricidad, agua y suministro de gas al consumidor final",
    prtrtabler4: "Construcción",
    prtrtabler5: "Industrias manufactureras",
    prtrtabler6: "Comercio al por mayor",
    prtrtabler7: "Comercio al por menor",
    prtrtabler8: "Transporte, correos y almacenamiento",
    prtrtabler9: "Información en medios masivos",
    readLess: "[-]",
    readMore: "[+]",
    releasesAir: "Emisiones al aire, en sitio",
    releasesLand: "Emisiones o disposición al suelo en sitio",
    releasesWater: "Descargas en aguas superficiales, en sitio",
    reports: "PANORAMA DE LOS DATOS",
    results: "Resultados",
    retc: "RETC",
    retcGHGLink: "https://apps1.semarnat.gob.mx:8443/retc/index.html",
    retcLink: "https://apps1.semarnat.gob.mx:8443/retc/index.html",
    rightToKnowLink: "https://web.doh.state.nj.us/rtkhsfs/spindexfs.aspx?lan=spanish",
    share: "Comparte esto a través de",
    sodiumNitrite: "Nitrito de sodio",
    stylebullet10: "Los niveles de exposición",
    stylebullet11: "límites legales",
    stylebullet5: "Todas las sustancias potencialmente dañinas.",
    stylebullet6: "Todas las fuentes de contaminantes.",
    stylebullet7: "Emisiones y transferencias de todos los contaminantes de una planta.",
    stylebullet8: "Todas las plantas de los sectores que deben presentar registros.",
    stylebullet9: "Los destinos ambientales o los riesgos",
    stylep2: "Nota:",
    stylep5: "* Nota sobre los metales:",
    stylep6: "Nota:",
    subscribeBtn: "SUSCRIBIRSE",
    takingStockSummaryEnd: "y los hace accesibles a través del sitio web En balance en línea y la base de datos consultable, con información y análisis adicionales en la serie de informes En balance. De esta manera, la CCA promueve un mayor acceso y comprensión de las cantidades, fuentes y manejo de los contaminantes industriales y apoya las decisiones sobre prevención de la contaminación y sostenibilidad.",
    takingStockSummaryIntro: "Junto con la energía, los materiales y los artículos que usamos todos los días, los sectores industriales de América del Norte generan contaminantes que deben manejarse para proteger nuestra salud y el medio ambiente. Cada año, las instalaciones industriales informan a los registros de emisiones y transferencias de contaminantes (RETC) de Canadá, México y Estados Unidos las sustancias emitidas al aire, al agua o al suelo, enviadas a disposición final o transferidas para reciclaje u otro modo de gestión. La iniciativa RETC de América del Norte de la CCA reúne los datos de aproximadamente 40,000 instalaciones industriales en toda la región",
    totalPhosphorous: "Fósforo total",
    totalReleases: "Total Emisiones y Transferencias",
    toxFAQLink: "https://wwwn.cdc.gov/TSP/ToxFAQs/ToxFAQsLanding.aspx",
    tpnaExportTitle: "PrincipalesContaminantesPorCantidad",
    tpnaFooter: "** ’Transferencias para otro modo de gestión’ incluye las siguientes subcategorías:",
    tpnaSummary1: "Total, todos los contaminantes reportados:",
    tpnaTitle: "Principales contaminantes (por cantidad)",
    transfersRecycling: "Transferencias para reciclaje",
    tri: "TRI",
    triLink: "https://www.epa.gov/toxics-release-inventory-tri-program",
    tsoReport: "Informes En balance",
    tsoReportLink: "http://www.cec.org/es/publicaciones/?_series=en-balance",
    tsoTitle: "CCA - En balance",
    tsScopeMethod: "En balance: Alcance y metodología",
    tssmbullet1: "Los datos RETC de cada país se compilan para la base de datos integrada del RETC de América del Norte, {style}. Ello entraña la estandarización de los campos de datos empleados en los tres países (véase el apartado “{link1}”).",
    tssmbullet10: "{style} a los contaminantes a que están sujetas las poblaciones humanas o de los ecosistemas.",
    tssmbullet11: "Los {style} establecidos para los contaminantes que las plantas emiten o transfieren. Los datos no indican si la planta cumple con las licencias y otros reglamentos pertinentes.",
    tssmbullet12: "{link1}, Agency for Toxic Substances and Disease Registry de Estados Unidos [Preguntas frecuentes sobre sustancias peligrosas, Departamento de Sustancias Tóxicas y Registro de Enfermedades de EU]",
    tssmbullet12link: "ToxFAQs",
    tssmbullet13: "State of New Jersey, Department of Health, {link1} [Hojas informativas sobre sustancias peligrosas, por el derecho a la información, Departamento de Salud del estado de Nueva Jersey] (información disponible en español)",
    tssmbullet13link: "Right-to-Know Hazardous Substances Fact Sheets",
    tssmbullet1link: "Terminología",
    tssmbullet2: "Ciertas sustancias registradas en lo individual se han agregado en grupos o categorías de contaminantes (plomo y sus compuestos, isómeros de xileno). En estos casos no se asigna un número CAS específico al grupo de contaminantes.",
    tssmbullet3: "Los datos se someten a una revisión general para identificar posibles inconsistencias o errores, que luego se comunican a los programas RETC nacionales. Aunque la CCA no puede ser responsable de registros erróneos por parte de las plantas, la meta del proyecto RETC de América del Norte es usar los mejores datos disponibles.",
    tssmbullet4: "Los datos de cada año de registro (ya desde 2006) se actualizan cuando menos una vez al año, punto que los lectores han de recordar, sobre todo al tratar de emplear los datos para analizar tendencias temporales. Los cambios realizados a los datos pueden consultarse en los sitios web de los RETC nacionales.",
    tssmbullet5: "{style} Los datos proporcionan información únicamente sobre aquellos contaminantes que se registran en cada uno de los RETC.",
    tssmbullet6: "{style} El informe incluye sólo las plantas de los sectores industriales nacionales o de actividades industriales particulares sujetas al requisito de registro en cada programa nacional. Los RETC de América del Norte no incluyen las emisiones de automóviles u otras fuentes móviles, de fuentes naturales —como incendios forestales— o de fuentes agrícolas, que pueden contribuir con una proporción elevada de las cantidades totales en el caso de algunos contaminantes.",
    tssmbullet7: "{style} Sólo se incluyen los contaminantes que cumplen los umbrales de registro.",
    tssmbullet8: "{style} En Canadá y Estados Unidos únicamente las plantas con el equivalente de diez o más empleados de tiempo completo deben presentar registros (salvo algunas excepciones). En México no existe el umbral de empleo.",
    tssmbullet9: "{style} de los contaminantes emitidos o transferidos.",
    tssmheader1: "Limitaciones de los datos de los RETC",
    tssmlist1: "La metodología empleada en la preparación del informe {style} y la base de datos en línea incluye lo siguiente:",
    tssmlist2: "Los datos de los RETC de América del Norte no proporcionan información sobre:",
    tssmp1: "La CCA obtiene los datos de los registros de emisiones y transferencias de contaminantes de los tres países de los respectivos gobiernos o de sus sitios web públicos.",
    tssmp2: "{style} Los conjuntos de datos de los sistemas RETC nacionales evolucionan de manera constante conforme los establecimientos industriales revisan sus informes previos para corregir errores o realizar otros cambios.",
    tssmp3: "Todos los datos registrados por los tres países están disponibles en En balance en línea, a través de la base de datos integrada del RETC de América del Norte, con excepción de los correspondientes a contaminantes atmosféricos de criterio (CAC) y gases de efecto invernadero (GEI). Los registros de CAC están incluidos en el NPRI canadiense y los de GEI en el RETC mexicano, pero estos grupos de contaminantes no están sujetos a registro en el TRI de Estados Unidos. Sin embargo, se dispone de otras fuentes de información sobre las emisiones de estos contaminantes en los tres países, datos que pueden consultarse en: {link1}.",
    tssmp3link: "CAC/GEI",
    tssmp4: "Debido a los requisitos de registro de los RETC nacionales, que incluyen umbrales de registro tanto para los contaminantes como para las plantas, el informe En balance captura sólo una fracción de toda la contaminación industrial en América del Norte, además de que las plantas industriales no son las únicas fuentes de contaminación en la región.",
    tssmp5: "Las sustancias emitidas o transferidas por las plantas industriales tienen características físicas y químicas que influyen en su destino último y sus consecuencias para la salud humana y el medio ambiente. La evaluación del posible daño derivado de las emisiones de un contaminante particular al medio ambiente es tarea compleja que requiere información adicional, ya que el potencial de una sustancia para causar daño se deriva de diversos factores, como su toxicidad inherente y la naturaleza de la exposición a la misma (por ejemplo, el potencial de riesgo de los asbestos enviados a un relleno sanitario seguro es considerado mucho más bajo que el planteado por los asbestos emitidos a la atmósfera).",
    tssmp6: "Los datos RETC por sí solos no pueden ofrecer información suficiente para evaluar el daño potencial de un contaminante. Sin embargo, pueden emplearse en combinación con otra información sobre la sustancia como punto de partida para aprender más sobre sus posibles efectos. Entre las fuentes de información adicional figuran:",
    tssmtitle: "En balance: Alcance y metodología",
    twitterLink: "https://twitter.com/CCA_Espanol",
    twitterShareLink: "https://twitter.com/intent/tweet?text=",
    undergroundInjection: "Inyección subterránea en sitio",
    understandingTS: "Comprensión de En balance",
    unitedStates: "Estados Unidos",
    usNAICSLink: "https://www.census.gov/naics/",
    usToCanada: "Total EE-UU a Canadá",
    usToMexico: "Total EE-UU a México",
    year: "Año",
    youtubeLink: "https://www.youtube.com/user/CECweb",
};
