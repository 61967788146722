/* eslint-disable no-magic-numbers */
export const version = "3.2.1.1f6337c";
export const years = [
    "allYears",
    2022,
    2021,
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
    2009,
    2008,
    2007,
    2006
];

