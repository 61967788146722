import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import { NumericFormat } from "react-number-format";
import { years } from "../../config";
import { facilityCount, getIndustryReportURL, pollutantCount, topIndustries } from "../../utilities/Utility";
import {
    BarChartData,
    ChartData,
    LineChartData,
    ResponseData,
    Response,
    IDHashTable,
    IDReducer,
    Results,
    sumBarChartValues,
    sumLineChartValues
} from "../Chart";
import { LoadingSpinner } from "../ChartComponents/LoadingSpinner";
import CECChart from "../ChartComponents/CECChart";
import { CountResponse } from "../ChartComponents/ChartSection";
import { CultureContext } from "../../context/CultureContext";
import { ChartContext } from "../../context/ChartContext";

interface IndustryChartProps {
    mediaType: { text: string; value: string; mediaTypeValue: string; sortProperty: string };
}

export default function IndustryChart({ mediaType }: IndustryChartProps) {
    const { formatMessage } = useIntl();
    const culture = useContext(CultureContext);
    const { values } = useContext(ChartContext);
    const { year, agencyID, chemicalTypeID } = values;

    const [loading, setLoading] = useState<boolean>(false);
    const [statsLoading, setStatsLoading] = useState<boolean>(false);
    const [barChartData, setBarChartData] = useState<BarChartData>([]);
    const [lineChartData, setLineChartData] = useState<LineChartData>([]);
    const [facilityCountData, setFacilityCountData] = useState<number>();
    const [pollutantCountData, setPollutantCountData] = useState<number>();
    const [chartTotal, setChartTotal] = useState<number>();
    const [industryData, setIndustryData] = useState<IDHashTable>({});
    const allOtherSectorName = formatMessage({ id: "allOtherSectors" });
    const [totalReleases, setSetTotalReleases] = useState<number>(0);

    const reducer = (results: Results, item: ChartData) => {
        const itemYear = item.year;
        if (!results[itemYear]) {
            results[itemYear] = [item];
        } else {
            results[itemYear].push(item);
        }
        return results;
    };
    const groupByYear = (items: ChartData[]) => {
        return items.reduce(reducer, {});
    };

    const handleIndustryOnClick = (state: { name: string }) => {
        if (!state.name) {
            return;
        }
        const industryID = barChartData.find((d) => d.name === state.name)?.id;
        if (industryID) {
            const url = getIndustryReportURL(
                culture,
                mediaType.mediaTypeValue,
                industryID.toString(),
                year.value,
                chemicalTypeID.key,
                agencyID.key
            );
            window.open(url);
        }
    };
    const addIndustries = (items: ChartData[]) => {
        return items.reduce(IDReducer, {});
    };

    const handleIndustryDotClick = (industry: string) => {
        if (industry === allOtherSectorName) {
            return;
        }
        const industryID = Object.entries(industryData).find(([key]) => key === industry)?.[1];

        if (industryID || industryID === 0) {
            const url = getIndustryReportURL(
                culture,
                mediaType.mediaTypeValue,
                industryID.toString(),
                undefined,
                chemicalTypeID.key,
                agencyID.key
            );
            window.open(url);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let yearQuery = undefined;
                if (year.value === "allYears") {
                    yearQuery = years.slice(1, years.length).join(",");
                }
                const url = topIndustries(
                    culture,
                    yearQuery ?? year.text,
                    mediaType.mediaTypeValue,
                    agencyID.key,
                    chemicalTypeID.key
                );
                const totalReleasesAndTransfersURL = topIndustries(
                    culture,
                    yearQuery ?? year.text,
                    "29",
                    agencyID.key,
                    chemicalTypeID.key
                );
                const facilityCountURL = facilityCount(
                    culture,
                    mediaType.sortProperty,
                    yearQuery ?? year.text,
                    agencyID.key,
                    chemicalTypeID.key
                );
                const pollutantCountURL = pollutantCount(
                    culture,
                    mediaType.sortProperty,
                    yearQuery ?? year.text,
                    agencyID.key,
                    chemicalTypeID.key
                );
                setLoading(true);
                const responseData: Response = await axios.get(url);
                setLoading(false);

                const result = (responseData.data as ResponseData[]).map((item) => {
                    return {
                        total: item.Value,
                        name: item.Key.Name,
                        year: item.Year,
                        id: item.Key.ID
                    };
                });

                const filteredAllOthers = result.filter((r) => r.name !== allOtherSectorName);

                if (year.value !== "allYears") {
                    if (JSON.stringify(filteredAllOthers) !== JSON.stringify(barChartData)) {
                        setChartTotal(sumBarChartValues(result));
                        setBarChartData(filteredAllOthers);
                    }
                } else {
                    setIndustryData(addIndustries(result));
                    const itemsGroupedByYear = groupByYear(result);
                    const numItems = 11;
                    const topItemsByYear = Object.values(itemsGroupedByYear).map((i) =>
                        i
                            .sort((a, b) => b.total - a.total)
                            .filter((item) => item.name !== formatMessage({ id: "allOtherPollutants" }))
                            .slice(0, numItems)
                    );

                    const filteredItems = topItemsByYear.map((ti) => ti.filter((t) => t.name !== allOtherSectorName));

                    const allYearsChartData: LineChartData = filteredItems.map((top) =>
                        top.reduce(
                            (prev, item) => {
                                const name = item.name;
                                return { ...prev, year: item.year, [name]: item.total };
                            },
                            { year: 0 }
                        )
                    );

                    const allYearsTotalData: LineChartData = topItemsByYear.map((top) =>
                        top.reduce(
                            (prev, item) => {
                                const name = item.name;
                                return { ...prev, year: item.year, [name]: item.total };
                            },
                            { year: 0 }
                        )
                    );
                    setChartTotal(sumLineChartValues(allYearsTotalData));
                    setLineChartData(allYearsChartData);
                }
                setStatsLoading(true);
                const { data: facilityCountResponse }: CountResponse = await axios.get(facilityCountURL);
                const { data: pollutantCountResponse }: CountResponse = await axios.get(pollutantCountURL);
                const { data: releaseAndTransferResponse }: Response = await axios.get(totalReleasesAndTransfersURL);
                const releaseAndTransferResult = (releaseAndTransferResponse as ResponseData[]).map((item) => {
                    return {
                        total: item.Value,
                        name: item.Key.Name,
                        year: item.Year,
                        id: item.Key.ID
                    };
                });
                setSetTotalReleases(
                    Math.round(releaseAndTransferResult.reduce((prevItem, currItem) => prevItem + currItem.total, 0))
                );
                setStatsLoading(false);
                if (facilityCountResponse) {
                    setFacilityCountData(facilityCountResponse.Count);
                }
                if (pollutantCountResponse) {
                    setPollutantCountData(pollutantCountResponse.Count);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData().catch(() => {});
    }, [year, culture, mediaType, agencyID, chemicalTypeID]); /* eslint-disable-line react-hooks/exhaustive-deps */

    const getFormattedNumber = (val: number | undefined) => {
        if (val === undefined) {
            return "undefined";
        }
        return <NumericFormat value={val} displayType={"text"} thousandSeparator={culture === "fr-CA" ? " " : true} />;
    };

    const getDropDownText = () => {
        return (
            <>
                <br />
                <FormattedMessage id="industryText" />
            </>
        );
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <CECChart
                barChartData={barChartData}
                lineChartData={lineChartData}
                chartName={"Industry"}
                chartFileName={`${formatMessage({ id: "industryExportTitle" })}`}
                summaryID={"industrySummary"}
                footerID={"tpnaFooter"}
                facilityCount={statsLoading ? formatMessage({ id: "loading" }) : getFormattedNumber(facilityCountData)}
                pollutantCount={
                    statsLoading ? formatMessage({ id: "loading" }) : getFormattedNumber(pollutantCountData)
                }
                handleOnClick={handleIndustryOnClick}
                handleDotClick={handleIndustryDotClick}
                xAxisBarLabelID={"kilograms"}
                yAxisLineLabelID={"kilograms"}
                totalReleases={statsLoading ? formatMessage({ id: "loading" }) : getFormattedNumber(totalReleases)}
                chartTotal={
                    chartTotal === undefined ? formatMessage({ id: "loading" }) : getFormattedNumber(chartTotal)
                }
                mediaTypeValue={mediaType.value}
                dropdownText={getDropDownText()}
            />
        </>
    );
}
