import React from "react";
import { Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";

import "./layout.css";

interface ShareProps {
    href: string;
    title: string;
}

const Share = (props: ShareProps) => {
    const { formatMessage } = useIntl();
    const buttonStyle: React.CSSProperties = {
        fontSize: 16,
        height: 30,
        width: 30,
        display: "flex",
        paddingTop: 13,
        marginRight: 8,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        lineHeight: "0.8"
    };

    const text = `${formatMessage({ id: "cecFullName" })} - ${formatMessage({ id: "liststyle1" })}: ${props.title}`;
    const cleanedText = text.replace("`", "'");
    const facebookShareLink = `${formatMessage({ id: "facebookShareLink" })}${props.href}`;
    const linkedinShareLink = `${formatMessage({ id: "linkedinShareLink" })}${props.href}&summary=${cleanedText}`;
    const twitterShareLink = `${formatMessage({ id: "twitterShareLink" })}${cleanedText} - ${props.href}`;
    const mailShareLink = `${formatMessage({ id: "mailShareLink" })}${cleanedText}&body=${props.href}`;
    return (
        <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
            <p style={{ fontSize: 15, lineHeight: "45px", fontFamily: '"Open Sans", sans-serif', margin: 0 }}>
                <FormattedMessage id="share" />:
            </p>
            <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
                <Button
                    style={buttonStyle}
                    className="first-icon"
                    color="facebook"
                    icon="facebook f"
                    as="a"
                    target="_blank"
                    href={facebookShareLink}
                />
                <Button
                    style={buttonStyle}
                    className="next-icon"
                    color="linkedin"
                    icon="linkedin in"
                    as="a"
                    target="_blank"
                    href={linkedinShareLink}
                />
                <Button
                    style={buttonStyle}
                    className="next-icon"
                    color="twitter"
                    icon="twitter"
                    as="a"
                    target="_blank"
                    href={twitterShareLink}
                />
                <Button
                    style={buttonStyle}
                    className="next-icon"
                    color="black"
                    icon="envelope"
                    as="a"
                    target="_blank"
                    href={mailShareLink}
                />
            </div>
        </div>
    );
};

export default Share;
