import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "react-tooltip";
import prtrImage from "../images/PRTR-v5.webp";
import prtrImageES from "../images/PRTR-SP.webp";
import prtrImageFR from "../images/PRTR-FR.webp";
import "./prtr.css";

interface PrtrProps {
    lang: string;
    updateMediaType: (val: string) => void;
    mediaType: string;
}

export default function Prtr({ lang, updateMediaType, mediaType }: PrtrProps) {
    const clickSection = (selectedMediaType: string) => {
        const isActive = selectedMediaType === mediaType ? "activeMediaType" : "";
        return (
            <button
                className={`prtrImageSection ${selectedMediaType} ${isActive}`}
                onClick={() => updateMediaType(selectedMediaType)}
            ></button>
        );
    };
    return (
        <div id="PRTRContainer">
            <h2 style={{ textAlign: "center" }}>
                <FormattedMessage id={"interactiveGraphicTitle"} />
            </h2>
            <p style={{ textAlign: "center" }}>
                <FormattedMessage id={"interactiveGraphicText"} />
            </p>
            <div style={{ position: "relative" }}>
                <img
                    src={lang === "fr" ? prtrImageFR : lang === "es" ? prtrImageES : prtrImage}
                    alt="The different ways pollutants can be released on transferred in the environment."
                    style={{ width: "100%" }}
                ></img>
                {clickSection("releasesWater")}
                {clickSection("undergroundInjection")}
                {clickSection("releasesAir")}
                {clickSection("releasesLand")}
                <Tooltip id="hoverDisposal" variant="light" place="top">
                    <div>
                        <FormattedMessage id="disposalFooter" />
                        <br />
                        <div className="hoverDisposalIndent">
                            <FormattedMessage id="disposalSubCatLandfill" />
                            <br />
                            <FormattedMessage id="disposalSubCatUndergroundInjection" />
                            <br />
                            <FormattedMessage id="disposalSubCatLandApplication" />
                            <br />
                            <FormattedMessage id="disposalSubCatStabilization" />
                            <br />
                            <FormattedMessage id="disposalSubCatStorage" />
                            <br />
                            <FormattedMessage id="disposalSubCatOther" />
                        </div>
                    </div>
                </Tooltip>
                <a data-tooltip-id="hoverDisposal" data-tooltip-float>
                    {clickSection("disposal")}
                </a>
                {clickSection("transfersRecycling")}
                <Tooltip id="hoverFurtherManagement" variant="light" place="top">
                    <div>
                        <FormattedMessage id="tpnaFooter" />
                        <br />
                        <div className="hoverFurtherManagementIndent">
                            <FormattedMessage id="furtherManagementTreatment" />
                            <br />
                            <FormattedMessage id="furtherManagementSewage" />
                            <br />
                            <FormattedMessage id="furtherManagementEnergy" />
                        </div>
                    </div>
                </Tooltip>
                <a data-tooltip-id="hoverFurtherManagement" data-tooltip-float>
                    {clickSection("furtherManagement")}
                </a>
            </div>
        </div>
    );
}
