import React, { useState } from "react";
import { Accordion, Icon, Table, AccordionTitleProps } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import "../components/layout.css";
import Share from "../components/Share";
import { ExcelLink, globalUrl } from "../utilities/Utility";
import PageWrapper from "./PageWrapper";

interface PrtrProps {
    lang: string;
    href: string;
}

export default function Prtr({ lang, href }: PrtrProps) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const { formatMessage } = useIntl();
    const index2 = 2;
    const index3 = 3;
    const index4 = 4;
    const index5 = 5;
    const title = formatMessage({ id: "prtrReportReqs" });

    const handleClick = (titleProps: AccordionTitleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : Number(index);
        setActiveIndex(newIndex);
    };
    return (
        <>
            <PageWrapper title={title} keywords={["PRTR", "reporting", "requirements"]} lang={lang} originalPath="prtr">
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                        style={{
                            maxWidth: 900,
                            margin: 50,
                            paddingTop: 50,
                            paddingBottom: 50
                        }}
                    >
                        <h1 style={{ fontSize: 36 }}>
                            <FormattedMessage id="prtrReportReqs" />
                        </h1>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="prtrIntro"
                                values={{
                                    link1: (
                                        <a href={formatMessage({ id: "npriLink" })} target="_blank">
                                            <FormattedMessage id="prtrIntrolink1" />
                                        </a>
                                    ),
                                    link2: (
                                        <a
                                            style={{ fontStyle: "italic" }}
                                            href={formatMessage({ id: "retcLink" })}
                                            target="_blank"
                                        >
                                            <FormattedMessage id="prtrIntrolink2" />
                                        </a>
                                    ),
                                    link3: (
                                        <a href={formatMessage({ id: "triLink" })} target="_blank">
                                            <FormattedMessage id="prtrIntrolink3" />
                                        </a>
                                    )
                                }}
                            />
                        </p>
                        <Accordion style={{ fontSize: 18, lineHeight: 1.5 }}>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={(e, titleProps) => handleClick(titleProps)}
                                style={{ color: "#4183c4" }}
                            >
                                <Icon name="dropdown" />
                                <FormattedMessage id="prtrtab1title" />
                            </Accordion.Title>
                            <Accordion.Content style={{ margin: 20 }} active={activeIndex === 0}>
                                <p>
                                    <a href={ExcelLink}>
                                        <FormattedMessage id="prtrtab1link" />
                                    </a>
                                </p>
                                <p>
                                    <FormattedMessage id="prtrtab1p1" />
                                </p>
                                <p>
                                    <strong>
                                        <FormattedMessage
                                            id="prtrtab1p2"
                                            values={{
                                                featuresLink: (
                                                    <a href={`${globalUrl}/content/landing/${lang}/features`}>
                                                        <FormattedMessage id="featuresThreeNA" />
                                                    </a>
                                                )
                                            }}
                                        />
                                    </strong>
                                </p>
                                <p>
                                    <FormattedMessage
                                        id="prtrtab1p3"
                                        values={{
                                            link1: (
                                                <a href={ExcelLink}>
                                                    <FormattedMessage id="prtrtab1p3link" />
                                                </a>
                                            )
                                        }}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage id="prtrtab1p4" />
                                </p>
                                <ul>
                                    <li>
                                        <FormattedMessage id="prtrtab1bullet3" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="prtrtab1bullet4" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="prtrtab1bullet5" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="prtrtab1bullet6" />
                                    </li>
                                </ul>
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={(e, titleProps) => handleClick(titleProps)}
                                style={{ color: "#4183c4" }}
                            >
                                <Icon name="dropdown" />
                                <FormattedMessage id="prtrtab2title" />
                            </Accordion.Title>
                            <Accordion.Content style={{ margin: 20 }} active={activeIndex === 1}>
                                <p>
                                    <FormattedMessage id="prtrtab2p1" />
                                </p>
                                <p>
                                    <FormattedMessage id="prtrtab2p2" />
                                </p>
                                <p>
                                    <FormattedMessage id="prtrtab2p3" />
                                </p>
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex === index2}
                                index={2}
                                onClick={(e, titleProps) => handleClick(titleProps)}
                                style={{ color: "#4183c4" }}
                            >
                                <Icon name="dropdown" />
                                <FormattedMessage id="prtrtab3title" />
                            </Accordion.Title>
                            <Accordion.Content style={{ margin: 20 }} active={activeIndex === index2}>
                                <p>
                                    <FormattedMessage id="prtrtab3p1" />
                                </p>
                                <p>
                                    <FormattedMessage id="prtrtab3list1" />
                                </p>
                                <ul>
                                    <li>
                                        <a href={formatMessage({ id: "npriLink" })} target="_blank">
                                            <FormattedMessage id="prtrtab3bullet1" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formatMessage({ id: "neiLink" })} target="_blank">
                                            <FormattedMessage id="prtrtab3bullet2" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formatMessage({ id: "inemLink" })} target="_blank">
                                            <FormattedMessage id="prtrtab3bullet3" />
                                        </a>
                                    </li>
                                </ul>
                                <p>
                                    <FormattedMessage id="prtrtab3list2" />
                                </p>
                                <ul>
                                    <li>
                                        <a href={formatMessage({ id: "openDataCanadaLink" })} target="_blank">
                                            <FormattedMessage id="prtrtab3bullet4" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formatMessage({ id: "ghgLink" })} target="_blank">
                                            <FormattedMessage id="prtrtab3bullet5" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formatMessage({ id: "retcGHGLink" })} target="_blank">
                                            <FormattedMessage id="prtrtab3bullet6" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formatMessage({ id: "ineccLink" })} target="_blank">
                                            <FormattedMessage id="prtrtab3bullet7" />
                                        </a>
                                    </li>
                                </ul>
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex === index3}
                                index={3}
                                onClick={(e, titleProps) => handleClick(titleProps)}
                                style={{ color: "#4183c4" }}
                            >
                                <Icon name="dropdown" />
                                <FormattedMessage id="prtrtab4title" />
                            </Accordion.Title>
                            <Accordion.Content style={{ margin: 20 }} active={activeIndex === index3}>
                                <p>
                                    <FormattedMessage id="prtrtab4p1" />
                                </p>
                                <ul>
                                    <li>
                                        <FormattedMessage id="prtrtab4bullet1" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="prtrtab4bullet2" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="prtrtab4bullet3" />
                                    </li>
                                </ul>
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex === index4}
                                index={4}
                                onClick={(e, titleProps) => handleClick(titleProps)}
                                style={{ color: "#4183c4" }}
                            >
                                <Icon name="dropdown" />
                                <FormattedMessage id="prtrtab5title" />
                            </Accordion.Title>
                            <Accordion.Content style={{ margin: 20 }} active={activeIndex === index4}>
                                <p>
                                    <FormattedMessage id="prtrtab5p1" />
                                </p>
                                <ul>
                                    <li>
                                        <a href={formatMessage({ id: "CanadaNAICSLink" })} target="_blank">
                                            <FormattedMessage id="canada" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formatMessage({ id: "usNAICSLink" })} target="_blank">
                                            <FormattedMessage id="unitedStates" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formatMessage({ id: "mexNAICSLink" })} target="_blank">
                                            <FormattedMessage id="mexico" />
                                        </a>
                                    </li>
                                </ul>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                <FormattedMessage id="prtrtab5tabletitle1" />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <FormattedMessage id="prtrtab5tabletitle2" />
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>11</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler1" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>21</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler2" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>22</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler3" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>23</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler4" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>31/32/33</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler5" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>41/42/43</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler6" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>44/45/46</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler7" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>48/49</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler8" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>51</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler9" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>52</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler10" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>53</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler11" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>54</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler12" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>55</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler13" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>56</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler14" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>61</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler15" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>62</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler16" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>71</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler17" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>72</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler18" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>81</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler19" />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>91/92/93</Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage id="prtrtabler20" />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <p>
                                    <FormattedMessage id="prtrtab5p2" />
                                </p>
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex === index5}
                                index={5}
                                onClick={(e, titleProps) => handleClick(titleProps)}
                                style={{ color: "#4183c4" }}
                            >
                                <Icon name="dropdown" />
                                <FormattedMessage id="prtrtab6title" />
                            </Accordion.Title>
                            <Accordion.Content style={{ margin: 20 }} active={activeIndex === index5}>
                                <FormattedMessage id="prtrtab6p1" />
                            </Accordion.Content>
                        </Accordion>
                        <Share href={href} title={title} />
                    </div>
                </div>
            </PageWrapper>
        </>
    );
}
