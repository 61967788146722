import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Container } from "semantic-ui-react";
import tsVideo from "../images/title.webm";
import tsFrVideo from "../images/title-fr.webm";
import tsEsVideo from "../images/title-es.webm";
import introOne from "../images/intro-3.webp";
import introTwo from "../images/intro-2.webp";
import introThree from "../images/intro-1.webp";
import "./layout.css";
import InfoImage from "./InfoImage";

export default function TakingStock() {
    const { locale } = useIntl();
    const [readMore, setReadMore] = useState(false);
    const readMoreLessStyle = { color: "#4b7b83", fontWeight: "bold", cursor: "pointer" };
    return (
        <div style={{ color: "black", backgroundColor: "#77C4D0", paddingTop: 120 }}>
            <div style={{ textAlign: "center" }}>
                <Container text data-testid="tso-image">
                    <div style={{ position: "relative" }}>
                        <a data-tooltip-id="hoverTSO" data-tooltip-float>
                            <video key={locale} width="75%" autoPlay loop muted playsInline>
                                {locale === "es" ? (
                                    <source src={tsEsVideo} type="video/webm" />
                                ) : locale === "fr" ? (
                                    <source src={tsFrVideo} type="video/webm" />
                                ) : (
                                    <source src={tsVideo} type="video/webm" />
                                )}
                            </video>
                        </a>
                    </div>
                </Container>
                <Container text>
                    <div>
                        <p>
                            <FormattedMessage id="takingStockSummaryIntro" />

                            {readMore ? (
                                <span>
                                    {" "}
                                    <FormattedMessage id="takingStockSummaryEnd" />{" "}
                                    <a onClick={() => setReadMore(false)}>
                                        <FormattedMessage id="readLess">
                                            {(txt) => <span style={readMoreLessStyle}>{txt}</span>}
                                        </FormattedMessage>
                                    </a>
                                </span>
                            ) : (
                                <a onClick={() => setReadMore(true)}>
                                    {"… "}
                                    <FormattedMessage id="readMore">
                                        {(txt) => <span style={readMoreLessStyle}>{txt}</span>}
                                    </FormattedMessage>
                                </a>
                            )}
                        </p>
                    </div>
                </Container>
                <div className="infoImageContainer">
                    <div className={"info-container"}>
                        <InfoImage
                            imageSource={introOne}
                            titleId={"introTitleOne"}
                            paragraphId={"introTextOne"}
                            imageType={"electronic"}
                            hoverId={"hoverOne"}
                        />
                        <InfoImage
                            imageSource={introTwo}
                            titleId={"introTitleTwo"}
                            paragraphId={"introTextTwo"}
                            imageType={"fossil"}
                            hoverId={"hoverTwo"}
                        />
                        <InfoImage
                            imageSource={introThree}
                            titleId={"introTitleThree"}
                            paragraphId={"introTextThree"}
                            imageType={"foodProd"}
                            hoverId={"hoverThree"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
