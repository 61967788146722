module.exports = {
    about: "À propos",
    actionPlan: "Plan d’action",
    actionPlanLink: "http://www.cec.org/fr/publications/plan-daction-pour-lamelioration-de-la-comparabilite-des-registres-des-rejets-et-des-transferts-de-polluants-rrtp-en-amerique-du-nord/",
    allOtherPollutants: "Autres polluants",
    allOtherSectors: "Autres secteurs",
    allPollutantTypes: "Tous les types de polluants",
    allYears: "Toutes les années",
    andCompounds: "* et ses composés",
    associated: "Exemples de polluants associés:",
    barium: "Baryum*",
    boldlist1: "rejets ou éliminations sur place",
    boldp2: "éliminations hors site",
    boldp4: "transferts pour autre mode de gestion",
    buttonHeader: "Explorez les analyses des données des RRTP nord-américains et les informations connexes dans la série de rapports À l’heure des comptes.",
    canada: "Canada",
    CanadaNAICSLink: "https://www.statcan.gc.ca/fr/concepts/industrie",
    canadaToUS: "Total Canada aux É-U",
    cbtAction: "Sélectionnez une année pour voir les quantités transférées entre les pays.",
    cbtLink: "Explorez les transferts transfrontaliers",
    cbtTitle: "Transferts transfrontaliers",
    cbtTotal: "Transferts transfrontaliers totaux:",
    cecHomePageLink: "http://www.cec.org/fr",
    chromium: "Chrome*",
    clickBarIndustryExplore: "Cliquez sur une barre pour explorer les données de ce secteur industriel dans la base de données.",
    clickBarPollutantExplore: "Cliquez sur une barre pour explorer les données de ce polluant dans la base de données.",
    clickDotIndustryExplore: "Cliquez sur un point pour explorer les données de ce secteur industriel dans la base de données.",
    clickDotPollutantExplore: "Cliquez sur un point pour explorer les données de ce polluant dans la base de données.",
    contact: "Contactez-nous",
    contactUs: "CONTACTEZ-NOUS",
    contactUsDescription: "L’Initiative nord-américaine de registre de rejets et transferts de polluants (RRTP)",
    contactUsEmail: "NAPRTR@cec.org",
    country: "Pays",
    devReproToxins: "Substances toxiques pour le développement ou la reproduction",
    disposal: "Transferts pour élimination*",
    disposalFooter: "* « Transferts pour élimination » comprend les sous-catégories suivantes :",
    disposalSubCatLandApplication: "c) pour épandage",
    disposalSubCatLandfill: "a) dans une décharge ou structure de retenue en surface",
    disposalSubCatOther: "f) pour autre élimination (autre)",
    disposalSubCatStabilization: "d) pour stabilisation ou traitement avant l’élimination",
    disposalSubCatStorage: "e) pour stockage avant l’élimination",
    disposalSubCatUndergroundInjection: "b) pour injection souterraine",
    email: "Courriel",
    exploreNewTSReport: "Explorez le nouveau rapport À l’heure des comptes!",
    export: "Exporter",
    facebookLink: "https://www.facebook.com/CCEconnexion",
    facebookShareLink: "https://www.facebook.com/sharer/sharer.php?u=",
    facilityCount: "Nombre d’installations",
    featuresp1: "À l’heure des comptes se fonde sur les renseignements que contiennent les trois RRTP nord-américains. Chaque RRTP comporte des listes de polluants et de secteurs industriels ainsi que des critères de déclaration qui lui sont propres et qui ont évolué avec le temps. Le tableau ci-dessous présente une comparaison des caractéristiques des trois RRTP.",
    featurestabler1c1: "Caractéristique",
    featurestabler1c2: "Inventaire national des rejets de polluants (INRP), Canada",
    featurestabler1c3: "Registro de Emisiones y Transferencia de Contaminantes (RETC), Mexique",
    featurestabler1c4: "Toxics Release Inventory (TRI), États-Unis",
    featurestabler2c1: "Première année de déclaration obligatoire",
    featurestabler3c1: "Activités ou secteurs pris en compte",
    featurestabler3c2: "Tout établissement qui fabrique ou utilise une substance chimique inscrite. Activités exclues : recherche, réparation, vente au détail, agriculture et foresterie. Tout établissement qui rejette dans l’air des quantités précisées de principaux contaminants atmosphériques.",
    featurestabler3c3: "Établissements dans 11 secteurs relevant de la compétence fédérale (en termes d’émissions atmosphériques): produits pétroliers, chimie/pétrochimie, fabrication de peintures et d’encres, métaux de première fusion et métaux ouvrés, automobile, pâtes et papiers, ciment/chaux, amiante, verre, production d’électricité et gestion des déchets dangereux. Autres établissements qui exercent des activités régies par les autorités fédérales - par exemple:",
    featurestabler3c3bullet1: "De grands générateurs (10 tonnes ou plus) de déchets dangereux (si les déchets transférés contiennent des substances RRTP en quantités égales ou supérieures au seuil de déclaration)",
    featurestabler3c3bullet2: "Les installations qui déchargent des eaux usées dans les plans d’eau nationaux (si les eaux usées contiennent des substances RRTP en quantités égales ou supérieures au seuil de déclaration).",
    featurestabler3c4: "Établissements de fabrication et établissements fédéraux, centrales électriques (au mazout et au charbon), mines de charbon et mines de métal, gestion des déchets dangereux et récupération des solvants, grossistes en produits chimiques, dépôts et terminaux de pétrole en vrac.",
    featurestabler4c1: "Nombre de polluants sujets à déclaration",
    featurestabler4c2: "Plus de 320 polluants (ou groupes de polluants).",
    featurestabler4c3: "200 polluants/groupes de polluants (depuis 2014).",
    featurestabler4c4: "Plus de 700 polluants et 33 catégories de polluants.",
    featurestabler5c1: "Seuil relatif au nombre d’employés",
    featurestabler5c2: "Généralement, 10 employés ou plus. Pour certaines activités, comme l’incinération des déchets et le traitement des eaux usées, le seuil de 10 employés ne s’applique pas.",
    featurestabler5c3: "Aucun seuil",
    featurestabler5c4: "10 employés à temps plein ou plus (ou nombre équivalent d’heures)",
    featurestabler6c1: "Seuils d’« activité » (substances fabriquées, traitées ou utilisées d’une autre manière) et seuils de rejet",
    featurestabler6c2: "Seuils d’« activité » de 10 000 kg pour la plupart des substances. Seuils plus bas pour certains polluants comme les substances toxiques, biocumulatives et persistantes (STBP), les hydrocarbures aromatiques polycycliques, les dioxines et furanes et les principaux contaminants atmosphériques.",
    featurestabler6c3: "Seuil de rejet et seuil d’« activité » pour chaque substance (tout établissement qui atteint ou excède l’un ou l’autre seuil est tenu à déclaration). Seuils de rejet (sauf pour les gaz à effet de serre) : entre 1 kg/an et 1 000 kg/an. Seuils d’« activité » : entre 5 kg/an et 5 000 kg/an. Tout rejet de biphényles polychlorés (BPC) et d’hexafluorure de soufre doit être déclaré. Dioxines et furanes déclarés, peu importe l’activité ou le volume rejeté.",
    featurestabler6c4: "“Seuils d’« activité » de 25 000 lb (environ 11 340 kg); et seuil de 10 000 lb (environ 5 000 kg) dans le cas des substances utilisées d’une autre manière. Seuils plus bas applicables aux STBP et aux dioxines et furanes.",
    featurestabler7c1: "Types de rejets et de transferts pris en compte",
    featurestabler7c2: "Sur place : rejets dans l’air, dans les eaux de surface, et sur le sol; et éliminations (incluant par injection souterraine). Hors site : transferts pour élimination, traitement avant élimination (incluant évacuation à l’égout), recyclage, et récupération d’énergie.",
    featurestabler7c3: "Sur place : rejets dans l’air, dans les eaux de surface, et sur le sol. Hors site : transferts pour élimination, recyclage, réemploi, récupération d’énergie, traitement, cotraitement (intrant provenant d’un autre procédé de production), évacuation à l’égout.",
    featurestabler7c4: "Sur place : rejets dans l’air, dans les eaux de surface, sur le sol, et via injection souterraine. Hors site : transferts pour élimination; recyclage, récupération d’énergie, traitement, évacuation à l’égout.",
    featuresThreeNA: "Caractéristiques des RRTP nord-américains",
    filtersSelected: "Filtres sélectionnés",
    footerCallToAction: "Vous souhaitez en savoir davantage?",
    footerInfo: "L’initiative du Registre nord-américain des rejets et transferts de polluants (RRTP) de la CCE favorise l’accès du public aux données et aux informations déclarées par les installations industrielles au Canada, au Mexique et aux États-Unis afin d’améliorer la compréhension des sources et de la gestion des polluants d’intérêt commun et d’appuyer les décisions relatives à la prévention de la pollution et à la durabilité.",
    footerSecondary: "Explorez les rejets et les transferts signalés par environ 40 000 installations industrielles en Amérique du Nord - par année, polluant, installation, secteur, pays ou région (y compris les transferts transfrontaliers).",
    for: "au",
    furtherManagement: "Transferts pour autre mode de gestion**",
    furtherManagementEnergy: "c) pour récupération d’énergie",
    furtherManagementSewage: "b) pour évacuation à l’égout/traitement",
    furtherManagementTreatment: "a) pour traitement",
    ghgLink: "https://www.epa.gov/ghgreporting",
    giveFeedback: "Donnez-nous votre",
    giveFeedbackLink: "https://docs.google.com/forms/d/e/1FAIpQLSd4icYwoFOhlo8FUa6yK0JX0R2eUA-7V7rdTorlHSGmd_2ojQ/viewform",
    glossarybullet1: "les rejets dans l’air;",
    glossarybullet2: "les rejets dans les eaux de surface;",
    glossarybullet3: "les rejets par injection souterraine;",
    glossarybullet4: "les éliminations ou rejets sur le sol.",
    glossarylist1: "Les {style} regroupent les rejets qui ont lieu à l’établissement même, c’est-à-dire les suivants :",
    glossaryp1: "Le rapport {style} utilise les catégories suivantes pour présenter l’information relative aux rejets et aux transferts de polluants.",
    glossaryp2: "Les {style} désignent les polluants qui sont transportés à un autre endroit aux fins d’élimination (par ex., dans une décharge ou structure de retenue en surface; pour injection souterraine).",
    glossaryp3: "Les {style} englobent les substances chimiques expédiées hors site aux fins de recyclage.",
    glossaryp4: "Les {style} désignent les polluants (autres que les métaux*) qui sont expédiés hors site à des établissements de traitement, de récupération d’énergie ou d’épuration des eaux usées.",
    glossaryp5: "{style} : Les quantités déclarées de métaux qui sont transférées hors site pour élimination, évacuation à l’égout, traitement ou récupération d’énergie sont incluses dans la catégorie des éliminations hors site, parce que ces métaux peuvent être séparés des déchets et éliminés dans des décharges, ou selon d’autres méthodes. Cette intégration des métaux dans la catégorie des éliminations hors site tient compte des caractéristiques physiques des métaux et du fait qu’ils ne sont pas détruits lorsqu’ils sont soumis à des procédés de traitement.",
    glossaryp6: "{style} : Du fait que cette terminologie est propre aux rapports À l’heure des comptes, les termes « rejet » et « transfert » tels qu’ils sont définis ici peuvent avoir un sens différent de celui qui leur est donne dans les rapports de l’INRP, du RETC et du TRI.",
    glossarytitle: "Terminologie",
    home: "Accueil",
    hoverText1: "Les différences entre les exigences nationales en matière de déclaration pour les polluants et les secteurs doivent être prises en compte lorsqu’on intrerprète les données des RRTP. Pour plus d’informations, voir “Comprendre À l’heure des comptes.”",
    hoverText2: "Chaque RRTP comporte sa propre liste de substances : l’INRP vise près de 350 polluants; le TRI, environ 650; et le RETC, 200.",
    hydrochloricAcid: "Acide chlorhydrique",
    impconNaics1part1: "Le Système de classification des industries de l’Amérique du Nord (SCIAN) a été élaboré par les organismes statistiques du Canada, du Mexique et des États-Unis en 1997. La structure du SCIAN est hiérarchique et comprend un code à six chiffres (les deux premiers chiffres désignent le secteur, le le troisième désigne le sous-secteur, et ainsi de suite). Les codes du SCIAN ont été incorporés dans les rapports du RRTP en 2006 et les utilisateurs d’À l’heure des comptes en ligne peuvent interroger les données aux niveaux du SCIAN-3, du SCIAN-4 et du SCIAN-5.",
    impconNaics1Title: "Codes du Système de classification des industries de l’Amérique du Nord (SCIAN)",
    impconNaics2part1: "Les données des RRTP nord-américains reflètent la taille et la composition de la base industrielle de chaque pays, ainsi que les différences entre les exigences de déclaration des RRTP nationaux par rapport aux secteurs et activités industriels :",
    impconNaics2part1ca1: "Toutes les installations qui respectent les seuils et les exigences de déclaration doivent déclarer, à quelques exceptions près (p. ex., les installations de recherche).",
    impconNaics2part1mx1: "Onze secteurs sous réglementation fédérale, ainsi que les installations d’autres secteurs qui se livrent à des activités soumises à la réglementation fédérale (par exemple, les installations qui manipulent des déchets dangereux ou rejettent des polluants dans des plans d’eau nationaux) sont tenus de déclarer.",
    impconNaics2part1us1: "Les installations fédérales, la plupart des installations de fabrication et les industries qui desservent les installations de fabrication (par exemple, les services publics d’électricité, les installations de gestion des déchets dangereux) doivent déclarer. Quelques secteurs basés sur les ressources (par exemple, l’extraction de pétrole et de gaz) sont exemptés.",
    impconNaics2part2: "À quelques exceptions près, seules les installations canadiennes et américaines qui atteignent le seuil d’employés (généralement, 10 employés à temps plein ou l’équivalent en heures travaillées) sont assujetties à la déclaration au RRTP.",
    impconNaics2Title: "Critères de déclaration des RRTP relatifs aux secteurs industriels",
    impconTpna1part1: "Chaque pays a des exigences de déclaration uniques relatives aux polluants :",
    impconTpna1part1ca: "L’INRP canadien:",
    impconTpna1part1ca1: "plus de 320 substances",
    impconTpna1part1mx: "Le RETC mexicain:",
    impconTpna1part1mx1: "200 substances (avant 2014, il y en avait 104)",
    impconTpna1part1us: "Le TRI des États-Unis:",
    impconTpna1part1us1: "plus de 700 substances (et 33 catégories chimiques)",
    impconTpna1part2: "Chaque programme comprend également des seuils de déclaration des polluants, certains polluants ayant des seuils inférieurs en raison de leur plus grand potentiel de nuire à la santé humaine et/ou à l’environnement.",
    impconTpna1part3: "Pour consulter la liste des substances déclarées aux RRTP nord-américains :",
    impconTpna1Title: "Critères de déclaration des RRTP relatifs aux polluants",
    impconTpna2part1: "Certaines substances dans À l’heure des comptes sont classées dans une ou plusieurs des catégories suivantes :",
    impconTpna2part2: "Nota : Les données sur les rejets de principaux contaminants atmosphériques (PCA) et de gaz à effet de serre (GES) ne sont pas incluses dans À l’heure des comptes en raison des différences entre les exigences nationales de déclaration des RRTP pour ces polluants.",
    impconTpna2Title: "Types de polluants dans À l’heure des comptes",
    impconTpna3part1: "En plus de la quantité rejetée ou transférée, d’autres facteurs doivent être pris en considération lorsqu’on essaie d’évaluer si une substance présente un risque pour la santé humaine ou l’environnement. Ceux-ci incluent la toxicité inhérente du polluant, son potentiel à persister dans l’environnement ou à le modifier d’une manière ou d’une autre, le milieu de rejet, le niveau d’exposition; etc.",
    impconTpna3part2: "Potentiel d’équivalence de toxicité (P-ET):",
    impconTpna3part3: "Pour certains polluants rejetés dans l’air ou dans l’eau,",
    impconTpna3part4: "À l’heure des comptes fournit également des valeurs P-ET, qui indiquent le potentiel d’une substance à causer le cancer ou d’autres effets (non cancérigènes).",
    impconTpna3Title: "Facteurs à prendre en compte lors de l’utilisation des données des RRTP pour évaluer les risques",
    industryExportTitle: "PrincipalesIndustries",
    industrySummary: "Total (toutes les industries déclarantes):",
    industryText: "Les secteurs industriels sont affichés au niveau SCIAN-4.",
    industryTitle: "Principales industries (par quantité)",
    ineccLink: "https://www.gob.mx/inecc/acciones-y-programas/inventario-nacional-de-emisiones-de-gases-y-compuestos-de-efecto-invernadero",
    inemLink: "https://www.gob.mx/semarnat/acciones-y-programas/inventario-nacional-de-emisiones-de-contaminantes-criterio-inem",
    instagramLink: "https://www.instagram.com/cceameriquedunord/",
    interactiveGraphicText: "Cliquez sur l’une des catégories dans l’image ci-dessous, puis utilisez les filtres dans les graphiques associés pour explorer les données disponibles pour environ 40 000 établissements dans la région.",
    interactiveGraphicTitle: "Vous voulez en savoir plus sur les rejets et transferts de polluants industriels en Amérique du Nord ?",
    introTextOne: "La fabrication de produits électroniques peut engendrer d’importantes quantités de déchets potentiellement dangereux.",
    introTextThree: "Les polluants rejetés dans l’eau par le secteur de la production et de la transformation d’aliments peuvent contribuer à l’eutrophisation ou appauvrir les plans d’eau en oxygène",
    introTextTwo: "Les émissions attribuables à la combustion de combustibles fossiles peuvent produire des gaz acides et causer des troubles respiratoires.",
    introTitleOne: "produits électroniques",
    introTitleThree: "production alimentaire",
    introTitleTwo: "combustibles fossiles",
    kilograms: "kilogrammes",
    knownOrSusCarc: "Cancérogènes connus ou présumés",
    lead: "Plomb*",
    learnMore: "En savoir plus.",
    learnMoreLink: "http://www.cec.org/fr/polluants/suivi-des-rejets-et-des-transferts-de-polluants-en-amerique-du-nord/",
    linkedinLink: "https://www.linkedin.com/company/commission-for-environmental-cooperation-of-north-america",
    linkedinShareLink: "https://www.linkedin.com/shareArticle?mini=true&url=",
    liststyle1: "À l’heure des comptes",
    loading: "Téléchargement en cours...",
    mailShareLink: "mailto:?subject=",
    mainDatabaseLink: "Consultez la base de données principale",
    manganese: "Manganèse*",
    mapLink: "Cartographiez les données",
    mediaType: "Type de rejet ou transfert",
    mediumLink: "https://medium.com/@cecweb",
    mercury: "Mercure*",
    metals: "Métaux",
    mexico: "Mexique",
    mexicoToUS: "Total Mexique aux É-U",
    mexNAICSLink: "http://www.inegi.org.mx/app/biblioteca/ficha.html?upc=702825099701",
    neiLink: "https://www.epa.gov/air-emissions-inventories/2014-national-emissions-inventory-nei-data",
    newsSignUpLink: "http://www.cec.org/fr/inscrivez-vous/",
    newTSReportURL: "http://www.cec.org/tsreports/fr/",
    nitricAcid: "Acide nitrique / Composés de nitrate",
    northAmerica: "Amérique du Nord",
    northAmericanPRTRDB: "Base de données RRTP d’Amérique du Nord",
    npri: "INRP",
    npriLink: "https://www.canada.ca/fr/services/environnement/pollution-gestion-dechets/inventaire-national-rejets-polluants.html",
    openDataCanadaLink: "https://ouvert.canada.ca/data/fr/dataset/a8ba14b7-7f23-462a-bdbb-83b0ef629823",
    persBioToxic: "Substances toxiques, biocumulatives et persistantes (STBP)",
    pollutantCount: "Nombre de polluants",
    pollutantType: "Type de polluant",
    prtrIntro: "Un registre des rejets et transferts de polluants (RRTP) fournit des données annuelles sur les rejets de polluants que font les établissements sur place, que ce soit dans l’air, l’eau, ou le sol, par injection souterraine, ou pour l’élimination; et hors site pour le recyclage, la transformation ou l’élimination. Le registre nord-américain de rejets et de transferts de polluants (RNARTP) de la CCE met les données des RRTP nationaux à la disposition du public pour aider à mieux comprendre les sources et la gestion des polluants qui suscitent des préoccupations communes. Les données accessibles dans À l’heure des comptes sont déclarées par les établissements au RRTP de chaque pays : {link1} du Canada, le {link2} (RETC, Registre des émissions et des transferts de polluants) du Mexique et le {link3} (TRI, Inventaire des rejets toxiques) des États-Unis. L’intégration, l’analyse et la diffusion des données visent à accroître leur utilité.",
    prtrIntrolink1: "l’Inventaire national des rejets de polluants (INRP)",
    prtrIntrolink2: "Registro de Emisiones y Transferencia de Contaminantes",
    prtrIntrolink3: "Toxics Release Inventory",
    prtrReportReqs: "Les RRTP et leurs critères de déclaration",
    prtrtab1bullet3: "Cancérogènes connus ou présumés, selon le Centre international de recherche sur le cancer (CIRC) de l’Organisation mondial de la santé, et la liste des substances désignées au terme de la Proposition 65 par l’Office of Environmental Health Hazard Assessment (OEHHA) de la Californie;",
    prtrtab1bullet4: "Substances toxiques pour le développement ou la reproduction, désignées au terme de la Proposition 65 de la Californie;",
    prtrtab1bullet5: "Substances toxiques, biocumulatives et persistantes (STBP), dont les propriétés en font une menace à long terme pour l’environnement et la santé humaine, même en petites quantités;",
    prtrtab1bullet6: "Métaux : les métaux sont présents naturellement dans l’environnement, mais certaines activités humaines comme l’extraction minière et les procédés de fusion en accroissent les concentrations dans le milieu naturel. La toxicité de certains métaux et de leurs composés peut dépendre des formes qu’ils adoptent dans l’environnement.",
    prtrtab1link: "Télécharger la liste",
    prtrtab1p1: "Les polluants visés par les critères de déclaration des RRTP répondent à certains critères de toxicité chimique et de risque pour la santé humaine et l’environnement. Chaque RRTP comporte sa propre liste de substances : l’INRP vise plus de 320 polluants, le TRI, plus de 700, et depuis 2014, le RETC vise 200 substances. Chaque programme comprend également des seuils de déclaration des polluants selon que la substance est fabriquée, traitée ou autrement utilisée (FTU) ou rejetée. Certains polluants ont un seuil de déclaration inférieur en raison de leur plus grand potentiel de risque pour la santé humaine et l’environnement.",
    prtrtab1p2: "Pour plus d’informations: {featuresLink}",
    prtrtab1p3: "Pour voir la liste des polluants declarés aux RRTP nord-americains, veuillez consulter: {link1}.",
    prtrtab1p3link: "[Excel]",
    prtrtab1p4: "Pour pouvoir fournir plus d’information à propos des polluants visés par les RRTP, le rapport À l’heure des comptes et sa base de données en ligne les catégorisent comme suit :",
    prtrtab1title: "Quels polluants faut-il déclarer?",
    prtrtab2p1: "Le potentiel d’équivalence de toxicité (potentiel-ET) fournit des indications sur les risques relatifs pour la santé humaine associés au rejet d’une unité de polluant, comparativement au rejet d’une unité d’une substance chimique de référence. On calcule le potentiel-ET à l’aide du modèle CalTOX, élaboré par les organismes de réglementation de la Californie. Le potentiel-ET est un des nombreux outils de présélection, qui sont tous basés sur une série d’hypothèses et génèrent donc des résultats différents. Les potentiels-ET proviennent de Scorecard. Les informations d’évaluation des risques utilisées pour À l’heure des comptes sont révisées et mises à jour de temps à autre.",
    prtrtab2p2: "Les lecteurs doivent savoir que l’analyse fondée sur le potentiel-ET est limitée, car un rejet n’est pas directement lié aux expositions concrètes ou aux niveaux de risque. En outre, on n’a pas attribué un potentiel-ET à toutes les substances (parfois, on n’a pas d’information sur leur toxicité ou le potentiel d’exposition à ces substances). Même si ces polluants ne sont pas classés selon leur potentiel-ET, il ne faut pas supposer qu’ils ne présentent aucun risque. Il ne faut pas non plus supposer que d’autres types de rejets qui ne sont pas classés selon leur potentiel-ET (p. ex., les rejets dans le sol) sont sans danger.",
    prtrtab2p3: "On ne connaît le potentiel-ET que pour les rejets dans l’air et dans l’eau. La substance chimique de référence pour les cancérogènes est le benzène; pour les non-cancérogènes, c’est le toluène. Dans le rapport À l’heure des comptes et la base de données connexe, on multiplie le facteur de pondération du potentiel-ET par la quantité de substance rejetée pour obtenir la note de chaque polluant.",
    prtrtab2title: "Potentiel d’équivalence de toxicité (potentiel-ET) pour les rejets dans l’air et dans l’eau",
    prtrtab3bullet1: "Canada – Inventaire national des rejets de polluants (INRP)",
    prtrtab3bullet2: "États-Unis – National Emissions Inventory (NEI)",
    prtrtab3bullet3: "Mexique – Inventario Nacional de Emisiones de Contaminantes Criterio (INEM)",
    prtrtab3bullet4: "Canada – Programme de déclaration des gaz à effet de serre",
    prtrtab3bullet5: "États-Unis – Greenhouse Gas Reporting Program",
    prtrtab3bullet6: "Mexique – Registro de Emisiones y Transferencia de Contaminantes (RETC)",
    prtrtab3bullet7: "Mexique – Inventario Nacional de Emisiones de Gases y Compuestos de Efecto Invernadero",
    prtrtab3list1: "principaux contaminants atmosphériques :",
    prtrtab3list2: "Gaz à effet de serre :",
    prtrtab3p1: "Les données relatives aux rejets de principaux contaminants atmosphériques (PCA) et de gaz à effet de serre (GES) ne sont pas présentées dans À l’heure des comptes en raison des différences entre les trois pays sur le plan des critères de déclaration de ces polluants. Les PCA – monoxyde de carbone, oxydes d’azote, particules, oxydes de soufre et composés organiques volatils – sont un groupe de substances chimiques associées à des répercussions environnementales telles que le smog, les précipitations acides et le brouillard régional, et à des effets sur la santé tels que les maladies respiratoires. Les principales sources de PCA sont la combustion de combustibles fossiles, l’extraction des ressources naturelles et un éventail d’activités manufacturières. Les GES contribuent aux changements climatiques en emprisonnant la chaleur dans l’atmosphère de la Terre. Ils sont visés par le Protocole de Kyoto, qui est entré en vigueur en 2005. Les principaux GES sont le dioxyde de carbone, le méthane, l’oxyde nitreux et trois groupes de gaz fluorés. Certaines des principales sources anthropiques de GES sont la combustion de combustibles fossiles, la déforestation et les activités agricoles. Les PCA sont déclarés à l’INRP canadien et les GES, au RETC mexicain, mais ces polluants ne sont pas assujettis à une déclaration au TRI américain. Par contre, il existe d’autres sources d’information dans les trois pays :",
    prtrtab3title: "Déclaration des principaux contaminants atmosphériques  (PCA) et des gaz à effet de serre (GES)",
    prtrtab4bullet1: "Au Canada, sont visés par l’INRP tous les établissements qui satisfont aux critères de déclaration (dont les seuils), sauf quelques secteurs de l’exploitation des ressources naturelles et ceux qui se livrent à certaines activités, tels que les laboratoires de recherche.",
    prtrtab4bullet2: "Au Mexique, tous les secteurs industriels relevant de la compétence fédérale sont tenus à déclaration au RETC, de même que les établissements d’autres secteurs dont les activités sont sous réglementation fédérale, notamment ceux qui gèrent des déchets dangereux ou qui rejettent des eaux usées dans des eaux réceptrices nationales.",
    prtrtab4bullet3: "Aux États-Unis, le TRI vise les établissements fédéraux, de même que la plupart des établissements manufacturiers et les industries qui leur sont associées (p. ex. services d’électricité et gestion des déchets dangereux). Quelques secteurs de l’exploitation des ressources naturelles, dont certains connexes à l’exploitation pétrolière et gazière, ne sont pas tenus de faire des déclarations.",
    prtrtab4p1: "Dans chaque pays, les établissements faisant partie de secteurs industriels donnés ou se livrant à des activités industrielles particulières sont tenus de faire une déclaration aux RRTP.",
    prtrtab4title: "Quels secteurs font des déclarations?",
    prtrtab5p1: "Le Canada, le Mexique et les États-Unis ont créé le Système de classification des industries de l’Amérique du Nord (SCIAN), dont les codes servent à catégoriser les activités industrielles d’un établissement. Les codes SCIAN ont été créés en 1997 et, depuis 2006, on les a intégrés au système de déclaration aux RRTP, afin de remplacer les codes de classification type des industries (CTI) utilisés par chaque pays. Même si la catégorisation et les codes des sous-secteurs varient quelque peu d’un pays à l’autre, la répartition des secteurs dans des catégories générales est la même dans les trois pays, et ce sont ces catégories générales qui sont utilisées dans le présent rapport. Pour en savoir plus à propos de la mise en œuvre du SCIAN dans chaque pays :",
    prtrtab5p2: "Les critères de déclaration se fondent en partie sur les activités de chaque établissement plutôt que sur le seul code SCIAN attribué à celui-ci. En d’autres termes, ce ne sont pas tous les établissements d’un secteur donné qui sont visés. Par exemple, dans le secteur économique englobant le nettoyage à sec, les établissements qui procèdent au nettoyage à sec peuvent être tenus de faire des déclarations, mais non les points de ramassage et de livraison. Autre exemple : une usine de transformation des aliments qui produit sa propre électricité serait tenue de faire des déclarations.",
    prtrtab5tabletitle1: "Code SCIAN",
    prtrtab5tabletitle2: "Industrie",
    prtrtab5title: "Système de classification des industries de l’Amérique du Nord",
    prtrtab6p1: "L’INRP et le TRI comportent un seuil relatif au nombre d’employés, qui équivaut généralement à 10 employés à plein temps (avec certaines exceptions visant les polluants ou certains types d’établissements). Il n’existe pas de seuil relatif au nombre d’employés dans le RETC. Les renseignements supplémentaires sur les modalités de déclaration de l’INRP, du RETC et du TRI se trouvent sur leur site Web respectif.",
    prtrtab6title: "Seuils relatifs au nombre d’employés",
    prtrtabler1: "Agriculture, foresterie, pêche et chasse",
    prtrtabler10: "Finance et assurances",
    prtrtabler11: "Services immobiliers et services de location et de location à bail",
    prtrtabler12: "Services professionnels, scientifiques et techniques",
    prtrtabler13: "Gestion de sociétés et d’entreprises",
    prtrtabler14: "Services administratifs, services de soutien, services de gestion des déchets et services d’assainissement",
    prtrtabler15: "Services d’enseignement",
    prtrtabler16: "Soins de santé et assistance sociale",
    prtrtabler17: "Arts, spectacles et loisirs",
    prtrtabler18: "Hébergement et services de restauration",
    prtrtabler19: "Autres services (sauf les administrations publiques)",
    prtrtabler2: "Extraction minière, exploitation en carrière et extraction de pétrole et de gaz",
    prtrtabler20: "Administrations publiques",
    prtrtabler3: "Services publics (électricité, réseaux d’aqueduc et d’égouts, distribution de gaz naturel)",
    prtrtabler4: "Construction",
    prtrtabler5: "Fabrication",
    prtrtabler6: "Commerce de gros",
    prtrtabler7: "Commerce de détail",
    prtrtabler8: "Transport et entreposage",
    prtrtabler9: "Industrie de l’information et industrie culturelle",
    readLess: "[-]",
    readMore: "[+]",
    releasesAir: "Rejets dans l’air, sur place",
    releasesLand: "Éliminations ou rejets sur le sol sur place",
    releasesWater: "Rejets dans les eaux de surface, sur place",
    reports: "APERÇU DES DONNÉES",
    results: "Résultats",
    retc: "RETC",
    retcGHGLink: "https://apps1.semarnat.gob.mx:8443/retc/index.html",
    retcLink: "https://apps1.semarnat.gob.mx:8443/retc/index.html",
    rightToKnowLink: "http://web.doh.state.nj.us/rtkhsfs/indexFs.aspx",
    share: "Partagez ceci via",
    sodiumNitrite: "Nitrite de sodium",
    stylebullet10: "des niveaux d’exposition",
    stylebullet11: "des limites réglementaires",
    stylebullet5: "de toutes les substances potentiellement nocives",
    stylebullet6: "de toutes les sources de contaminants",
    stylebullet7: "des rejets et transferts de tous les polluants des établissements",
    stylebullet8: "de tous les établissements des secteurs visés",
    stylebullet9: "du devenir dans l’environnement",
    stylep2: "Note:",
    stylep5: "* Note à propos des métaux",
    stylep6: "Nota",
    subscribeBtn: "S’ABONNER",
    takingStockSummaryEnd: "et les rend accessibles via le site Web À l’heure des comptes en ligne et sa base de données interrogeable, avec des informations et des analyses supplémentaires dans la série de rapports À l’heure des comptes. De cette manière, la CCE favorise un meilleur accès et une meilleure compréhension des quantités, des sources et de la gestion des polluants industriels et appuie les décisions concernant la prévention de la pollution et la durabilité.",
    takingStockSummaryIntro: "Avec l’énergie, les matériaux et les articles que nous utilisons quotidiennement, les secteurs industriels nord-américains génèrent des polluants qui doivent être gérés pour protéger notre santé et notre environnement. Chaque année, les substances rejetées dans l’air, l’eau ou le sol, envoyées à l’élimination ou transférées au recyclage ou à un autre mode de gestion sont déclarées par les installations industrielles aux registres des rejets et transferts de polluants (RRTP) du Canada, du Mexique et des États-Unis. L’initiative du RRTP nord-américain de la CCE rassemble les données d’environ 40 000 installations industrielles de la région",
    totalPhosphorous: "Phosphore total",
    totalReleases: "Total Rejets et Transferts",
    toxFAQLink: "https://wwwn.cdc.gov/TSP/ToxFAQs/ToxFAQsLanding.aspx",
    tpnaExportTitle: "PrincipauxPolluantsParQuantité",
    tpnaFooter: "** « Transferts pour autre mode de gestion » comprend les sous-catégories suivantes :",
    tpnaSummary1: "Total, tous les polluants déclarés:",
    tpnaTitle: "Principaux polluants (par quantité)",
    transfersRecycling: "Transferts pour recyclage",
    tri: "TRI",
    triLink: "https://www.epa.gov/toxics-release-inventory-tri-program",
    tsoReport: "Rapports À l’heure des comptes",
    tsoReportLink: "http://www.cec.org/fr/publications/?_series=a-lheure-des-comptes",
    tsoTitle: "CCE - À l’heure des comptes",
    tsScopeMethod: "À l’heure des comptes: Portée et méthodologie",
    tssmbullet1: "Les données des RRTP de chaque pays sont compilées, puis intégrées dans la base de données du RRTP nord-américain de la CCE. Ce processus comporte la normalisation des champs de données utilisés dans les trois pays (voir la section «{link1}» pour plus de détails).",
    tssmbullet10: "{style} des humains ou des communautés animales et végétales aux polluants;",
    tssmbullet11: "{style} fixées pour les polluants que rejettent ou transfèrent les établissements.",
    tssmbullet12: "{link1}, Agency for Toxic Substances and Disease Registry des États-Unis",
    tssmbullet12link: "ToxFAQs",
    tssmbullet13: "État du New Jersey, Department of Health, {link1} (information aussi disponible en espagnol)",
    tssmbullet13link: "Right-to-Know Hazardous Substances Fact Sheets",
    tssmbullet1link: "Terminologie",
    tssmbullet2: "Certaines substances déclarées individuellement sont rassemblées en groupes ou en catégories (p. ex., métaux et leurs composés, isomères du xylène). Dans ces cas-là, aucun numéro CAS n’est attribué au groupe de polluants.",
    tssmbullet3: "Les données font l’objet d’un examen général permettant de relever des incohérences ou des erreurs. Le cas échéant, celles-ci sont transmises aux responsables des RRTP nationaux. Même si la CCE ne peut être tenue responsable des erreurs des établissements, l’objectif de son projet de RRTP nord-américain est d’utiliser les meilleures données qui soient.",
    tssmbullet4: "Les données de chaque année de déclaration (2006 est la plus ancienne) sont mises à jour au moins une fois par an – les lecteurs ne doivent pas oublier ce point, particulièrement lorsqu’ils utilisent les données pour analyser les tendances chronologiques. Ils peuvent visiter les sites Web des RRTP nationaux pour connaître les changements apportés aux données.",
    tssmbullet5: "{style} – le rapport porte seulement sur les polluants déclarés au RRTP de chaque pays;",
    tssmbullet6: "{style} – le rapport ne tient compte que des établissements des secteurs industriels tenus de faire des déclarations aux RRTP nationaux ou dont les activités sont expressément visées. Les RRTP ne renferment pas de données sur les émissions attribuables aux sources mobiles (comme les véhicules automobiles), naturelles (comme les incendies de forêt) ou agricoles. Dans le cas de certains polluants, ces trois sources sont parfois loin d’être négligeables;",
    tssmbullet7: "{style} – seules sont incluses les substances pour lesquelles les seuils de déclaration sont atteints;",
    tssmbullet8: "{style} – au Canada et aux États-Unis, à quelques exceptions près, seuls les établissements comptant 10 employés ou plus à plein temps (ou l’équivalent) doivent produire des rapports. Au Mexique, il n’y a pas de seuils relatifs au nombre d’employés;",
    tssmbullet9: "{style} des substances rejetées ou transférées, ni des risques associés à ces substances;",
    tssmheader1: "Limites des données des RRTP",
    tssmlist1: "Les paragraphes qui suivent décrivent la méthode utilisée pour établir le rapport {style} ainsi que la base de données en ligne:",
    tssmlist2: "Les données des RRTP nord-américains ne font pas état:",
    tssmp1: "La CCE a extrait les données des trois RRTP nationaux de celles fournies par les trois gouvernements ou mises à la disposition du public sur leur site Web respectif.",
    tssmp2: "{style} les ensembles de données qui alimentent les RRTP évoluent constamment, étant donné que les établissements revoient des déclarations précédentes pour corriger des erreurs ou y apporter d’autres modifications.",
    tssmp3: "À l’exception de celles qui portent sur les principaux contaminants atmosphériques (PCA) et les gaz à effet de serre (GES), toutes les données déclarées par les trois pays sont accessibles grâce à la base de données intégrée du RRTP-NA, sur À l’heure des comptes en ligne. Les PCA sont déclarés à l’INRP canadien et les GES, au RETC mexicain, mais ces polluants ne sont pas assujettis à une déclaration au TRI américain. Par contre, il existe d’autres sources d’information à propos des émissions de ces polluants dans les trois pays, que les utilisateurs peuvent consulter pour obtenir plus de détails : {link1}.",
    tssmp3link: "PAC/GES",
    tssmp4: "En raison des critères de déclaration aux RRTP nationaux, qui comprennent les seuils établis pour les polluants et les établissements, seule une partie de la pollution industrielle totale est consignée. Par ailleurs, les établissements industriels ne sont pas les seules sources de pollution en Amérique du Nord.",
    tssmp5: "Les substances rejetées ou transférées ont des caractéristiques physiques et chimiques qui influent sur leur élimination finale et sur leurs incidences sur la santé humaine et la salubrité de l’environnement. Il est compliqué d’évaluer les dommages que peuvent causer à l’environnement certains rejets de polluants, parce qu’ils dépendent de divers facteurs, dont la toxicité inhérente à la substance et la nature de l’exposition à celle-ci (p. ex., le risque potentiel que présente l’envoi d’amiante dans un site d’enfouissement sécuritaire est nettement inférieur au risque que présente l’amiante rejetée dans l’air).",
    tssmp6: "À elles seules, les données des RRTP ne fournissent pas assez d’information pour qu’on puisse évaluer les dommages potentiellement causés par un polluant; par contre, lorsqu’on les combine à d’autres renseignements relatifs à ce même polluant, elles permettent d’en apprendre davantage sur ses effets potentiels. Les lecteurs devront peut-être consulter d’autres sources afin d’obtenir des renseignements additionnels :",
    tssmtitle: "À l’heure des comptes: Portée et méthodologie",
    twitterLink: "https://twitter.com/CCE_Francais",
    twitterShareLink: "https://twitter.com/intent/tweet?text=",
    undergroundInjection: "Injection souterraine sur place",
    understandingTS: "Comprendre À l’heure des comptes",
    unitedStates: "États-Unis",
    usNAICSLink: "https://www.census.gov/naics/",
    usToCanada: "Total É-U au Canada",
    usToMexico: "Total É-U au Mexique",
    year: "Année",
    youtubeLink: "https://www.youtube.com/user/CECweb",
};
