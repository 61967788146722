import React, { useEffect, useState } from "react";
import axios from "axios";
import { version } from "../config";
import { getDBVersionUrl } from "../utilities/Utility";

export default function Version() {
    const [dbVersion, setDbVersion] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = (await axios.get(getDBVersionUrl)).data as string;
                setDbVersion(result);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData().catch(() => {});
    }, []);
    return (
        <>
            <div className="version">{version}</div>
            <div className="dbversion">{dbVersion}</div>
        </>
    );
}
