import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "react-tooltip";
import { Image } from "semantic-ui-react";
import ImageHoverText from "./ImageHoverText";

interface InfoImageProps {
    imageSource: string;
    titleId: string;
    paragraphId: string;
    imageType: string;
    hoverId: string;
}

export default function InfoImage({ imageSource, titleId, paragraphId, imageType, hoverId }: InfoImageProps) {
    return (
        <>
            <div className={"info-stack"}>
                <a data-tooltip-id={hoverId} data-tooltip-float>
                    <Image src={imageSource} className={"info-image"} data-html2canvas-ignore />
                    <p className={"info-title"}>
                        <FormattedMessage id={titleId} />
                    </p>
                </a>
                <Tooltip id={hoverId} variant="light" place="top">
                    <ImageHoverText imageType={imageType} />
                </Tooltip>
                <p className={"info-paragraph"}>
                    <FormattedMessage id={paragraphId} />
                </p>
            </div>
        </>
    );
}
