module.exports = {
    about: "about",
    actionPlan: "actionPlan",
    actionPlanLink: "actionPlanLink",
    allOtherPollutants: "allOtherPollutants",
    allOtherSectors: "allOtherSectors",
    allPollutantTypes: "allPollutantTypes",
    allYears: "allYears",
    andCompounds: "andCompounds",
    associated: "associated",
    barium: "barium",
    boldlist1: "boldlist1",
    boldp2: "boldp2",
    boldp4: "boldp4",
    buttonHeader: "buttonHeader",
    canada: "canada",
    CanadaNAICSLink: "CanadaNAICSLink",
    canadaToUS: "canadaToUS",
    cbtAction: "cbtAction",
    cbtLink: "cbtLink",
    cbtTitle: "cbtTitle",
    cbtTotal: "cbtTotal",
    cecHomePageLink: "cecHomePageLink",
    chromium: "chromium",
    clickBarIndustryExplore: "clickBarIndustryExplore",
    clickBarPollutantExplore: "clickBarPollutantExplore",
    clickDotIndustryExplore: "clickDotIndustryExplore",
    clickDotPollutantExplore: "clickDotPollutantExplore",
    contact: "contact",
    contactUs: "contactUs",
    contactUsDescription: "contactUsDescription",
    contactUsEmail: "contactUsEmail",
    country: "country",
    devReproToxins: "devReproToxins",
    disposal: "disposal",
    disposalFooter: "disposalFooter",
    disposalSubCatLandApplication: "disposalSubCatLandApplication",
    disposalSubCatLandfill: "disposalSubCatLandfill",
    disposalSubCatOther: "disposalSubCatOther",
    disposalSubCatStabilization: "disposalSubCatStabilization",
    disposalSubCatStorage: "disposalSubCatStorage",
    disposalSubCatUndergroundInjection: "disposalSubCatUndergroundInjection",
    email: "email",
    exploreNewTSReport: "exploreNewTSReport",
    export: "export",
    facebookLink: "facebookLink",
    facebookShareLink: "facebookShareLink",
    facilityCount: "facilityCount",
    featuresp1: "featuresp1",
    featurestabler1c1: "featurestabler1c1",
    featurestabler1c2: "featurestabler1c2",
    featurestabler1c3: "featurestabler1c3",
    featurestabler1c4: "featurestabler1c4",
    featurestabler2c1: "featurestabler2c1",
    featurestabler3c1: "featurestabler3c1",
    featurestabler3c2: "featurestabler3c2",
    featurestabler3c3: "featurestabler3c3",
    featurestabler3c3bullet1: "featurestabler3c3bullet1",
    featurestabler3c3bullet2: "featurestabler3c3bullet2",
    featurestabler3c4: "featurestabler3c4",
    featurestabler4c1: "featurestabler4c1",
    featurestabler4c2: "featurestabler4c2",
    featurestabler4c3: "featurestabler4c3",
    featurestabler4c4: "featurestabler4c4",
    featurestabler5c1: "featurestabler5c1",
    featurestabler5c2: "featurestabler5c2",
    featurestabler5c3: "featurestabler5c3",
    featurestabler5c4: "featurestabler5c4",
    featurestabler6c1: "featurestabler6c1",
    featurestabler6c2: "featurestabler6c2",
    featurestabler6c3: "featurestabler6c3",
    featurestabler6c4: "featurestabler6c4",
    featurestabler7c1: "featurestabler7c1",
    featurestabler7c2: "featurestabler7c2",
    featurestabler7c3: "featurestabler7c3",
    featurestabler7c4: "featurestabler7c4",
    featuresThreeNA: "featuresThreeNA",
    filtersSelected: "filtersSelected",
    footerCallToAction: "footerCallToAction",
    footerInfo: "footerInfo",
    footerSecondary: "footerSecondary",
    for: "for",
    furtherManagement: "furtherManagement",
    furtherManagementEnergy: "furtherManagementEnergy",
    furtherManagementSewage: "furtherManagementSewage",
    furtherManagementTreatment: "furtherManagementTreatment",
    ghgLink: "ghgLink",
    giveFeedback: "giveFeedback",
    giveFeedbackLink: "giveFeedbackLink",
    glossarybullet1: "glossarybullet1",
    glossarybullet2: "glossarybullet2",
    glossarybullet3: "glossarybullet3",
    glossarybullet4: "glossarybullet4",
    glossarylist1: "glossarylist1({style})",
    glossaryp1: "glossaryp1({style})",
    glossaryp2: "glossaryp2({style})",
    glossaryp3: "glossaryp3({style})",
    glossaryp4: "glossaryp4({style})",
    glossaryp5: "glossaryp5({style})",
    glossaryp6: "glossaryp6({style})",
    glossarytitle: "glossarytitle",
    home: "home",
    hoverText1: "hoverText1",
    hoverText2: "hoverText2",
    hydrochloricAcid: "hydrochloricAcid",
    impconNaics1part1: "impconNaics1part1",
    impconNaics1Title: "impconNaics1Title",
    impconNaics2part1: "impconNaics2part1",
    impconNaics2part1ca1: "impconNaics2part1ca1",
    impconNaics2part1mx1: "impconNaics2part1mx1",
    impconNaics2part1us1: "impconNaics2part1us1",
    impconNaics2part2: "impconNaics2part2",
    impconNaics2Title: "impconNaics2Title",
    impconTpna1part1: "impconTpna1part1",
    impconTpna1part1ca: "impconTpna1part1ca",
    impconTpna1part1ca1: "impconTpna1part1ca1",
    impconTpna1part1mx: "impconTpna1part1mx",
    impconTpna1part1mx1: "impconTpna1part1mx1",
    impconTpna1part1us: "impconTpna1part1us",
    impconTpna1part1us1: "impconTpna1part1us1",
    impconTpna1part2: "impconTpna1part2",
    impconTpna1part3: "impconTpna1part3",
    impconTpna1Title: "impconTpna1Title",
    impconTpna2part1: "impconTpna2part1",
    impconTpna2part2: "impconTpna2part2",
    impconTpna2Title: "impconTpna2Title",
    impconTpna3part1: "impconTpna3part1",
    impconTpna3part2: "impconTpna3part2",
    impconTpna3part3: "impconTpna3part3",
    impconTpna3part4: "impconTpna3part4",
    impconTpna3Title: "impconTpna3Title",
    industryExportTitle: "industryExportTitle",
    industrySummary: "industrySummary",
    industryText: "industryText",
    industryTitle: "industryTitle",
    ineccLink: "ineccLink",
    inemLink: "inemLink",
    instagramLink: "instagramLink",
    interactiveGraphicText: "interactiveGraphicText",
    interactiveGraphicTitle: "interactiveGraphicTitle",
    introTextOne: "introTextOne",
    introTextThree: "introTextThree",
    introTextTwo: "introTextTwo",
    introTitleOne: "introTitleOne",
    introTitleThree: "introTitleThree",
    introTitleTwo: "introTitleTwo",
    kilograms: "kilograms",
    knownOrSusCarc: "knownOrSusCarc",
    lead: "lead",
    learnMore: "learnMore",
    learnMoreLink: "learnMoreLink",
    linkedinLink: "linkedinLink",
    linkedinShareLink: "linkedinShareLink",
    liststyle1: "liststyle1",
    loading: "loading",
    mailShareLink: "mailShareLink",
    mainDatabaseLink: "mainDatabaseLink",
    manganese: "manganese",
    mapLink: "mapLink",
    mediaType: "mediaType",
    mediumLink: "mediumLink",
    mercury: "mercury",
    metals: "metals",
    mexico: "mexico",
    mexicoToUS: "mexicoToUS",
    mexNAICSLink: "mexNAICSLink",
    neiLink: "neiLink",
    newsSignUpLink: "newsSignUpLink",
    newTSReportURL: "newTSReportURL",
    nitricAcid: "nitricAcid",
    northAmerica: "northAmerica",
    northAmericanPRTRDB: "northAmericanPRTRDB",
    npri: "npri",
    npriLink: "npriLink",
    openDataCanadaLink: "openDataCanadaLink",
    persBioToxic: "persBioToxic",
    pollutantCount: "pollutantCount",
    pollutantType: "pollutantType",
    prtrIntro: "prtrIntro({link1},{link2},{link3})",
    prtrIntrolink1: "prtrIntrolink1",
    prtrIntrolink2: "prtrIntrolink2",
    prtrIntrolink3: "prtrIntrolink3",
    prtrReportReqs: "prtrReportReqs",
    prtrtab1bullet3: "prtrtab1bullet3",
    prtrtab1bullet4: "prtrtab1bullet4",
    prtrtab1bullet5: "prtrtab1bullet5",
    prtrtab1bullet6: "prtrtab1bullet6",
    prtrtab1link: "prtrtab1link",
    prtrtab1p1: "prtrtab1p1",
    prtrtab1p2: "prtrtab1p2({featuresLink})",
    prtrtab1p3: "prtrtab1p3({link1})",
    prtrtab1p3link: "prtrtab1p3link",
    prtrtab1p4: "prtrtab1p4",
    prtrtab1title: "prtrtab1title",
    prtrtab2p1: "prtrtab2p1",
    prtrtab2p2: "prtrtab2p2",
    prtrtab2p3: "prtrtab2p3",
    prtrtab2title: "prtrtab2title",
    prtrtab3bullet1: "prtrtab3bullet1",
    prtrtab3bullet2: "prtrtab3bullet2",
    prtrtab3bullet3: "prtrtab3bullet3",
    prtrtab3bullet4: "prtrtab3bullet4",
    prtrtab3bullet5: "prtrtab3bullet5",
    prtrtab3bullet6: "prtrtab3bullet6",
    prtrtab3bullet7: "prtrtab3bullet7",
    prtrtab3list1: "prtrtab3list1",
    prtrtab3list2: "prtrtab3list2",
    prtrtab3p1: "prtrtab3p1",
    prtrtab3title: "prtrtab3title",
    prtrtab4bullet1: "prtrtab4bullet1",
    prtrtab4bullet2: "prtrtab4bullet2",
    prtrtab4bullet3: "prtrtab4bullet3",
    prtrtab4p1: "prtrtab4p1",
    prtrtab4title: "prtrtab4title",
    prtrtab5p1: "prtrtab5p1",
    prtrtab5p2: "prtrtab5p2",
    prtrtab5tabletitle1: "prtrtab5tabletitle1",
    prtrtab5tabletitle2: "prtrtab5tabletitle2",
    prtrtab5title: "prtrtab5title",
    prtrtab6p1: "prtrtab6p1",
    prtrtab6title: "prtrtab6title",
    prtrtabler1: "prtrtabler1",
    prtrtabler10: "prtrtabler10",
    prtrtabler11: "prtrtabler11",
    prtrtabler12: "prtrtabler12",
    prtrtabler13: "prtrtabler13",
    prtrtabler14: "prtrtabler14",
    prtrtabler15: "prtrtabler15",
    prtrtabler16: "prtrtabler16",
    prtrtabler17: "prtrtabler17",
    prtrtabler18: "prtrtabler18",
    prtrtabler19: "prtrtabler19",
    prtrtabler2: "prtrtabler2",
    prtrtabler20: "prtrtabler20",
    prtrtabler3: "prtrtabler3",
    prtrtabler4: "prtrtabler4",
    prtrtabler5: "prtrtabler5",
    prtrtabler6: "prtrtabler6",
    prtrtabler7: "prtrtabler7",
    prtrtabler8: "prtrtabler8",
    prtrtabler9: "prtrtabler9",
    readLess: "readLess",
    readMore: "readMore",
    releasesAir: "releasesAir",
    releasesLand: "releasesLand",
    releasesWater: "releasesWater",
    reports: "reports",
    results: "results",
    retc: "retc",
    retcGHGLink: "retcGHGLink",
    retcLink: "retcLink",
    rightToKnowLink: "rightToKnowLink",
    share: "share",
    sodiumNitrite: "sodiumNitrite",
    stylebullet10: "stylebullet10",
    stylebullet11: "stylebullet11",
    stylebullet5: "stylebullet5",
    stylebullet6: "stylebullet6",
    stylebullet7: "stylebullet7",
    stylebullet8: "stylebullet8",
    stylebullet9: "stylebullet9",
    stylep2: "stylep2",
    stylep5: "stylep5",
    stylep6: "stylep6",
    subscribeBtn: "subscribeBtn",
    takingStockSummaryEnd: "takingStockSummaryEnd",
    takingStockSummaryIntro: "takingStockSummaryIntro",
    totalPhosphorous: "totalPhosphorous",
    totalReleases: "totalReleases",
    toxFAQLink: "toxFAQLink",
    tpnaExportTitle: "tpnaExportTitle",
    tpnaFooter: "tpnaFooter",
    tpnaSummary1: "tpnaSummary1",
    tpnaTitle: "tpnaTitle",
    transfersRecycling: "transfersRecycling",
    tri: "tri",
    triLink: "triLink",
    tsoReport: "tsoReport",
    tsoReportLink: "tsoReportLink",
    tsoTitle: "tsoTitle",
    tsScopeMethod: "tsScopeMethod",
    tssmbullet1: "tssmbullet1({style},{link1})",
    tssmbullet10: "tssmbullet10({style})",
    tssmbullet11: "tssmbullet11({style})",
    tssmbullet12: "tssmbullet12({link1})",
    tssmbullet12link: "tssmbullet12link",
    tssmbullet13: "tssmbullet13({link1})",
    tssmbullet13link: "tssmbullet13link",
    tssmbullet1link: "tssmbullet1link",
    tssmbullet2: "tssmbullet2",
    tssmbullet3: "tssmbullet3",
    tssmbullet4: "tssmbullet4",
    tssmbullet5: "tssmbullet5({style})",
    tssmbullet6: "tssmbullet6({style})",
    tssmbullet7: "tssmbullet7({style})",
    tssmbullet8: "tssmbullet8({style})",
    tssmbullet9: "tssmbullet9({style})",
    tssmheader1: "tssmheader1",
    tssmlist1: "tssmlist1({style})",
    tssmlist2: "tssmlist2",
    tssmp1: "tssmp1",
    tssmp2: "tssmp2({style})",
    tssmp3: "tssmp3({link1})",
    tssmp3link: "tssmp3link",
    tssmp4: "tssmp4",
    tssmp5: "tssmp5",
    tssmp6: "tssmp6",
    tssmtitle: "tssmtitle",
    twitterLink: "twitterLink",
    twitterShareLink: "twitterShareLink",
    undergroundInjection: "undergroundInjection",
    understandingTS: "understandingTS",
    unitedStates: "unitedStates",
    usNAICSLink: "usNAICSLink",
    usToCanada: "usToCanada",
    usToMexico: "usToMexico",
    year: "year",
    youtubeLink: "youtubeLink",
};
